import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Router from "vue-router";
import Cookies from "js-cookie";
import HomeView from "../views/HomeView.vue";
import "@/assets/css/w3.css";
import "@/assets/css/table.css";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requireAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/launch",
    name: "launch",
    component: () => import("../views/AddMissionView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/launch_edit",
    name: "launch_edit",
    component: () => import("../views/EditMissionView.vue"),
    props: (route) => route.params,
    meta: { requireAuth: true },
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("../views/UploadView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/attch_upload",
    name: "attch_upload",
    component: () => import("../views/AttchUploadView.vue"),
    props: (route) => route.params,
    meta: { requireAuth: true },
  },
  {
    path: "/station_information",
    name: "station_information",
    component: () => import("../views/StationInformationView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/lora",
    name: "lora",
    component: () => import("../views/LoRaListView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/download",
    name: "download",
    component: () => import("../views/DownloadView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/download_list",
    name: "download_list",
    component: () => import("../views/DownloadListView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LogoutView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/change_password",
    name: "change_password",
    component: () => import("../views/ChangePassView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/control",
    name: "control",
    component: () => import("../views/ProjectcontrolView.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/manual",
    name: "manual",
    component: () => import("../views/ManualView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 看看 to 和 from 兩個 arguments 會吐回什麼訊息
  console.log("to: ", to);
  console.log("from: ", from);

  // 目的路由在meta上是否有設置requireAuth: true
  if (to.meta.requireAuth) {
    // 獲取Cookies當中的login資訊並取得token
    const info = Cookies.get("login");
    //console.log(token)
    if (info) {
      const token = JSON.parse(info).token;
      // 如果token不為空，且確實有這個欄位則讓路由變更
      if (token.length > 0 || token === undefined) {
        next();
      } else {
        // 未通過則導回login頁面
        next({ name: "login" });
      }
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
