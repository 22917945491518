import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w3-content w3-padding",
  style: {"max-width":"1200px"}
}
const _hoisted_2 = {
  class: "w3-col l12 m12 w3-margin-bottom",
  style: {"margin-right":"-5px","padding-left":"10px","padding-right":"10px","z-index":"-9"}
}
const _hoisted_3 = {
  class: "w3-border-bottom w3-border-light-grey w3-padding-16",
  style: {"font-family":"Microsoft JhengHei"}
}
const _hoisted_4 = {
  class: "field_name",
  style: {}
}
const _hoisted_5 = {
  class: "w3-col l12 m12 w3-margin-bottom",
  style: {"margin-right":"-5px","padding-left":"10px","padding-right":"10px","z-index":"-9"}
}
const _hoisted_6 = {
  class: "w3-border-bottom w3-border-light-grey w3-padding-16",
  style: {"font-family":"Microsoft JhengHei"}
}
const _hoisted_7 = { class: "field_name" }
const _hoisted_8 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("AttchUpload.edt_title")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("AttchUpload.edt_upload")), 1),
      _createElementVNode("input", {
        class: "input_form",
        type: "file",
        name: "colaunch_file",
        id: "colaunch_file",
        style: {"width":"100%","margin-top":"10px"},
        accept: ".txt",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileChange && _ctx.fileChange(...args)))
      }, null, 32)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("AttchUpload.picture_title")), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("AttchUpload.picture_upload1")), 1),
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("AttchUpload.picture_upload2")), 1),
      _createElementVNode("input", {
        class: "input_form",
        type: "file",
        name: "pic_file",
        id: "pic_file",
        style: {"width":"100%","margin-top":"10px"},
        accept: "image/*",
        multiple: "",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fileChange && _ctx.fileChange(...args)))
      }, null, 32),
      _createElementVNode("input", {
        style: {"height":"60px","width":"100%","margin-top":"10px","font-size":"36px","font-weight":"bold"},
        type: "submit",
        name: "send",
        value: _ctx.text_upload_button,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.upload_file && _ctx.upload_file(...args)))
      }, null, 8, _hoisted_8)
    ])
  ]))
}