<template>
  <div class="w3-content w3-padding" style="max-width: 1200px">
    <div
      class="w3-col l12 m12"
      style="
        margin-right: -5px;
        padding-left: 10px;
        padding-right: 10px;
        z-index: -9;
        margin-bottom: 0px;
      "
    >
      <div
        class="w3-col l12 m12"
        style="
          margin-right: -5px;
          padding-left: 10px;
          padding-right: 10px;
          z-index: -9;
          margin-bottom: 0px;
        "
      >
        <h3 style="margin-bottom: -15px">
          自動集成之微型無線電探空儀數據品質控制流程
        </h3>
        <h3
          class="w3-border-bottom w3-border-light-grey w3-padding-16"
          style="font-family: Microsoft JhengHei"
        >
          Automatically Integrated mini-radiosonde data Quality Control
          procedure (AIQC)
        </h3>

        <br />
        <h3>簡介</h3>
        <p>
          Storm tracker (以下簡稱ST) AIQC
          線上資料校驗系統，是由文化大學蘇世顥教授實驗室（Extreme Weather and
          Climate,
          EWaC）所發展。AIQC是基於機器學習模型為核心，模型考量多種影響探空資料之物理變數，是以基於物理訊息之機器學習（physical
          informed machine learning）模型。AIQC
          system便是基於此一核心，對ST微型探空儀所量測之資料，進行資料整合與品質控制的資料後處理系統。機器學習模型是以2018至2022年間在台灣所進行各項觀測實驗中，ST與Vaisala-RS41(以下簡稱VS)所有共同施放對比數據作為訓練資料，依此所發展出的ST探空的壓力、溫度與水氣之校正模型。關於本系統所使用之演算法和訓練資料可由參考資料獲得相關資訊。對於演算法或資料使用有任何問題，歡迎直接與蘇世顥教授聯繫取得進一步的資訊。
        </p>
        <h3>Introduction</h3>
        <p>
          The mini-radiosonde, Storm Tracker (hereafter referred to as ST), AIQC
          online data quality calibration system was developed by the laboratory
          of Extreme Weather and Climate (EWaC) at Chinese Culture University
          (CCU). The AIQC system is based on the machine learning model that
          consider multiple physical variables affecting radiosonde
          observations, employing a framework known as Physical Informed Machine
          Learning. The core of the AIQC system was designed to integrating and
          ensuring the data quality of the ST measurements with post-process
          data process.
        </p>
        <p>
          The machine learning models are trained using comparative data
          collected during various observational experiments in Taiwan between
          2018 and 2022, where ST and Vaisala-RS41 (hereafter referred to as
          VS)were simultaneously launched. This training data serves as the
          foundation for the development of the data correction model for
          pressure, temperature, and humidity measurements for ST radiosondes.
          For detailed information regarding the algorithms and training data
          employed in this system, please refer to the reference lists. If you
          have any questions regarding the algorithms or data usage, please feel
          free to contact Professor Shih-Hao Su for further information.
        </p>

        <h3>版權宣告</h3>
        <p>
          本線上平台所收集與產製之資料僅作爲學術研究使用，並使用CC BY-NC-SA4.0
          (姓名標示─非商業性─相同方式分享權)授權。
        </p>

        <h3>Copyright Notice</h3>
        <p>
          The information collected and produced on this online platform is used
          for academic research only and is licensed under CC BY-NC-SA4.0 .
        </p>

        <h3>致謝</h3>
        <p>
          本研究得到台灣國家科學技術委員會（NSTC）的支持，研究經費來自“山區雲氣候（MCC）”計畫和“台灣長期溫濕度觀測數據之驗證與重建”計畫。”
        </p>

        <h3>Acknowledgments</h3>
        <p>
          This study was supported by the National Science and Technology
          Council (NSTC) of Taiwan, and the research funding comes from "The
          Mountain Cloud Climatology (MCC) project" and the project
          "Verification and Reconstruction of long-term temperature and humidity
          observation data in Taiwan."
        </p>

        <h3>資料收集與使用政策：</h3>
        <p>
          在使用本平臺進行資料校正處理流程之前，您應詳細閱讀並同意我們的資料使用政策。您可以點擊此處展開並查閱完整的資料使用政策。您的使用行為將被視為對該政策的同意和遵守。以下為本平台的資料使用政策：
        </p>
        <p>
          1.
          本平台所蒐集之個人聯絡資訊僅作為資料上傳與下載之身份認證使用，平台不會將任何個人資訊提供給第三方個人或團體，亦不會利用於任何與與蒐集目的以外之用途。使用資料期間於申請帳號起至觀測實驗結束後進行統整，並依在法定資料保存年限後進行銷毀。當您於瞭解後填寫本表單，已同意接受本同意書之所有內容及其後修改變更規定。依據台灣個人資料保護法第3條規定，您可請求查詢或閱覽、製給複製本、補充或更正、停止蒐集/處理/利用或刪除您的個人資料。如您不提供或未提供正確之個人資料，或要求停止蒐集/處理/利用/刪除個人資料、服務訊息的取消訂閱，本平台將無法為您提供蒐集目的之相關服務。
        </p>
        <p>
          2.
          本平台在過程中所收集之資料，僅作為提供線上資料校驗服務與本團隊發展後續QC系統和ST探空儀器特性分析使用。在任何情形下，本平台都不會將使用者上傳之資料與資訊與其他個人或第三方機構共享使用。透過本平台處理後下載之資料，其使用權利與資料保管責任皆屬原資料提供者，亦不限制原資料提供者分享平台處理過後之資料。
        </p>
        <p>
          3. 本平台所產製之所有資料將採用CC
          BY-NC-SA(姓名標示─非商業性─相同方式分享權)授權。所產製之資料僅供非商業目的使用，以及要求使用者在共享和衍生作品時遵循相同的授權條款。
        </p>
        <p>
          4.
          為確保引用本平台資料時遵守學術倫理和引用慣例，並明確指出使用者應該如何正確引用資料以確保學術誠信。本平台所產製之資料在學術研究使用時，需正確引用本平台所提供之參考文獻，不需另外取得授權。引用文獻如下：
        </p>
        <div>
          <p>
            ● 吳閩倫。「新型微型探空儀—“Storm Tracker”
            邊界層觀測資料校正流程建置與資料修正能力比較」。碩士論文，中國文化大學地學研究所大氣科學組，2023。https://hdl.handle.net/11296/v4kg8f
          </p>
        </div>

        <div>
          <p>
            ● Chiao-Wei Chang, Shih-Hao Su*, Ting-Shuo Yo, Hung-Chi Kuo,
            Chien-Ming Wu, Po-Hsiung Lin, Min-Lun Wu, Wen-Wei Tzeng, 2022, The
            developing and evaluation on the machine learning-based approach of
            the mini-radiosonde- “Storm Tracker” data calibration,
            天氣分析與預報研討會
          </p>
        </div>

        <p>
          5.
          本平台所產製之資料禁止所有商業（盈利）性質之使用。若有商業性質使用之需求，商業使用者應聯繫本研究團隊主持人-蘇世顥教授，並取得書面授權，以確保商業使用者遵守使用條款並取得必要的授權。
        </p>

        <h3>Data Collection and Usage Policy:</h3>
        <p>
          Before proceeding with the data correction process on this platform,
          please read and agree to our Data Usage Policy. You can click here to
          expand and review the full Data Usage Policy. Your use of the platform
          will be considered as your acceptance and compliance with this policy.
          The following outlines the Data Usage Policy for this platform:
        </p>
        <p>
          1. Personal information collected by this platform is solely used for
          identity verification in data uploading and downloading processes. The
          platform will not provide any personal information to any third-party
          individuals or organizations, nor will it use this information for any
          purposes other than the intended data collection. The usage period of
          the data begins upon account application and continues until the
          conclusion of the observational experiment. By filling out this form
          after understanding its content, you agree to all the terms of this
          agreement and any subsequent modifications. According to Article 3 of
          Taiwan's Personal Data Protection Act, you may request to inquire or
          review, obtain copies, supplement or correct, stop
          collecting/processing/using, or delete your personal information. If
          you do not provide or provide incorrect personal information, or
          request to stop collecting/processing/using/deleting personal
          information or unsubscribe from service messages, this platform will
          be unable to provide you with related services for data collection
          purposes.
        </p>
        <p>
          2. Data collected by this platform during the process is used solely
          for providing online data validation services and for the development
          of subsequent QC systems and ST radiosonde instrument characteristic
          analyses by our team. The right to use data downloaded after
          processing through this platform belongs to the original data
          provider, and it does not limit the original data provider from
          sharing data processed by the platform.
        </p>
        <p>
          3. All data produced by this platform will be licensed under CC
          BY-NC-SA 4.0 . The data produced is intended for non-commercial use
          only, and users are required to follow the same licensing terms when
          sharing and creating derivative works.
        </p>
        <p>
          4. To ensure compliance with academic ethics and citation conventions
          when referencing data from this platform, users should be clear on how
          to correctly cite the data to ensure academic integrity. When using
          data produced by this platform for academic research, correct citation
          of the references provided by this platform is required, and no
          additional authorization is necessary. The citation is as follows:
        </p>
        <div>
          <p>
            ● 吳閩倫。「新型微型探空儀—“Storm Tracker”
            邊界層觀測資料校正流程建置與資料修正能力比較」。碩士論文，中國文化大學地學研究所大氣科學組，2023。https://hdl.handle.net/11296/v4kg8f
          </p>
        </div>

        <div>
          <p>
            ● Chiao-Wei Chang, Shih-Hao Su*, Ting-Shuo Yo, Hung-Chi Kuo,
            Chien-Ming Wu, Po-Hsiung Lin, Min-Lun Wu, Wen-Wei Tzeng, 2022, The
            developing and evaluation on the machine learning-based approach of
            the mini-radiosonde- “Storm Tracker” data calibration,
            天氣分析與預報研討會
          </p>
        </div>
        <p>
          5. All commercial (for-profit) use of data produced by this platform
          is prohibited. If there is a need for commercial use, commercial users
          should contact the PI of this research team, Prof. Su Shih-Hao, and
          obtain written authorization to ensure compliance with the terms of
          use and obtain the necessary authorization.
        </p>

        <h3>版本資訊：</h3>
        <h4>● AIQC 2023:</h4>
        <p>
          最新的AIQC ver. 2023
          是以梯度提升模型（GBM）作為核心，2018至2022年間在台灣所進行各項觀測實驗中，ST與VS所有共同施放對比數據作為訓練資料。此版本中前處理過程導入自動化資料集成模組、資料時間標記修正模組、探空升空判定模組、原始資料檢核模組、儀器器差統計修正模組等功能。器差統計資訊來自於270筆獨立收集的地面儀器平行測試實驗，資料解析度均一化也修改程序為在前處理過程內插後，所有資料通過AIQC之流程之方式進行。從此一版本起，AIQC核心依據不同資料使用之需求，區分為邊界層觀測特化模型
          (AIQC V2023-PBL)與一般大氣觀測模型(AIQC
          V2023-GA)兩種模式，其資料校正核心不同，使用者可依需求選用。資料後處理過程中，針對溫濕資料導入低通濾波器，並提供基礎資料視覺化模組等功能。
        </p>
        <h4>● AIQC 2022:</h4>
        <p>
          基於AIQC ver. 2021的使用者回饋，AIQC ver. 2022
          改以廣義線性模型（GLM）作為核心，使用包含TASSE、YESR2020、MCC/YESR2021等實驗在2018~2021年期間所收集到超過900筆共放資料進行模型訓練。此版本中導入儀器器差統計修正作為前處理的過程，器差統計資訊來自於200筆獨立收集的地面儀器平行測試實驗。
        </p>
        <h4>● AIQC2021:</h4>
        <p>
          AIQC ver. 2021
          是以梯度提升模型（GBM）作為核心，使用包含TASSE、YESR2020等實驗在2018~2020年期間所收集到超過483筆共放資料進行模型訓練。
        </p>

        <h3>Version Information:</h3>
        <h4>● AIQC 2023:</h4>
        <p>
          The new AIQC ver. 2023 was built around a Gradient Boosting Model
          (GBM). It is trained using comparative data collected during various
          observational experiments conducted in Taiwan between 2018 and 2022,
          involving simultaneous launches of ST and VS sondes. In this version,
          the preprocessing phase incorporates features such as automated data
          integration, time stamp correction, lunch-time detection, raw data
          validation, and instrument bias statistical correction modules. The
          instrument bias statistical information is derived from 270
          independently collected ground instrument parallel testing
          experiments. The data vertical resolution normalization and processing
          procedures have been modified to ensure that all data pass through the
          AIQC process after interpolation within the preprocessing phase in
          AIQC ver.2023. Starting from this version, the core of AIQC system was
          saprated into two methods based on different data usage purposes: the
          Boundary Layer Observations Specialized Model (AIQC V2023-PBL) and the
          General Atmospheric Observations Model (AIQC V2023-GA). These two
          methods have distinct data correction kernels, allowing users to
          choose according to their specific requirements. During the data
          post-processing phase, a low-pass filter is also applied to
          temperature and humidity data, and basic data visualization modules
          are provided in version 2023.
        </p>
        <h4>● AIQC 2022:</h4>
        <p>
          Based on user feedback from AIQC ver. 2021, AIQC ver. 2022 has adopted
          Generalized Linear Models (GLM) as the ML kernel. It was trained using
          over 900 co-launched data collected from experiments including TASSE,
          YESR2020, MCC/YESR2021, conducted between 2018 and 2021. In this
          version, statistical correction of instrument biases was introduced as
          part of the preprocessing phase. The instrument bias statistical
          information was derived from 200 independently collected ground
          instrument parallel testing experiments.
        </p>
        <h4>● AIQC 2021:</h4>
        <p>
          AIQC ver. 2021 was built around a Gradient Boosting Model (GBM) as the
          ML kernel. The model was trained with more than 483 co-launched
          datasets collected from experiments, including TASSE and YESR2020,
          conducted between 2018 and 2020.
        </p>

        <h4>不同版本之AIQC資料校正結果對比</h4>
        <h3>
          Comparison of AIQC data calibration results of different versions
        </h3>
        <img style="width: 100%" src="@/assets/img/skewt.png" />
        <p>
          在低層大氣不同版本AIQC之溫度資料的校正能力都很不錯的表現，AIQC2023的校正結果會比AIQC2022稍佳。AIQC2023在濕度資料校正能力的進步較為顯著。在低層大氣中，AIQC2023的兩個版本都修正了AIQC2022在邊界層濕度稍微偏乾的現象，同時AIQC2023_GA的版本大幅修正了中高層中低估大氣濕度的現象。
        </p>
        <p>
          The correction capabilities of temperature data of different versions
          of AIQC in the lower atmosphere are very good. The correction results
          of AIQC2023 will be slightly better than AIQC2022. AIQC2023 has made
          significant improvements in humidity data correction capabilities. In
          the lower atmosphere, both versions of AIQC2023 corrected the
          phenomenon of AIQC2022 being slightly drier in boundary layer
          humidity, while the AIQC2023_GA version significantly corrected the
          phenomenon of underestimating atmospheric humidity in the middle and
          upper layers.
        </p>

        <div
          class="w3-display-container"
          style="position: relative; width: 100%"
        >
          <img
            src="@/assets/img/EWaC.png"
            style="position: absolute; height: 200px; width: 200px; right: 0px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
