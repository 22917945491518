import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "st_list" }
const _hoisted_2 = {
  class: "w3-content w3-padding",
  style: {"max-width":"1200px"}
}
const _hoisted_3 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_4 = { class: "field_name" }
const _hoisted_5 = { value: "" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_8 = { class: "field_name" }
const _hoisted_9 = { value: "" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "w3-col l12 m12 w3-margin-bottom w3-row-padding" }
const _hoisted_12 = { class: "w3-col l12 m12 w3-margin-bottom w3-row-padding" }
const _hoisted_13 = {
  class: "w3-border-bottom w3-border-light-grey w3-padding-16",
  style: {"font-family":"Microsoft JhengHei"}
}
const _hoisted_14 = { class: "table" }
const _hoisted_15 = { scope: "col" }
const _hoisted_16 = { scope: "col" }
const _hoisted_17 = { scope: "col" }
const _hoisted_18 = { scope: "col" }
const _hoisted_19 = { scope: "row" }
const _hoisted_20 = ["value"]
const _hoisted_21 = ["value"]
const _hoisted_22 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_center = _resolveComponent("center")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("project")) + "：", 1),
        _withDirectives(_createElementVNode("select", {
          input: "",
          class: "input_form",
          style: {"float":"left","width":"100%"},
          name: "observer",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.project_change && _ctx.project_change(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pid) = $event))
        }, [
          _createElementVNode("option", _hoisted_5, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: project.id
            }, _toDisplayString(project.name), 9, _hoisted_6))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.pid]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("station")) + "*：", 1),
        _withDirectives(_createElementVNode("select", {
          input: "",
          class: "input_form",
          style: {"float":"left","width":"100%"},
          name: "observer",
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.station_change && _ctx.station_change(...args))),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sid) = $event))
        }, [
          _createElementVNode("option", _hoisted_9, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stations, (station, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: station.id
            }, _toDisplayString(station.name), 9, _hoisted_10))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.sid]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t("LoRaList.title")), 1),
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t("LoRaList.update_time")) + "：" + _toDisplayString(_ctx.update_time), 1),
          _createElementVNode("h5", null, "*" + _toDisplayString(_ctx.$t("LoRaList.note1")), 1),
          _createElementVNode("table", _hoisted_14, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t("LoRaList.id")), 1),
                _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("LoRaList.file_name")), 1),
                _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t("LoRaList.uploaded_time")), 1),
                _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t("LoRaList.delete")), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lora_list, (lora, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  style: _normalizeStyle(lora.COL_STYLE)
                }, [
                  _createElementVNode("th", _hoisted_19, _toDisplayString(lora.ID), 1),
                  _createElementVNode("td", null, _toDisplayString(lora.FILE_NAME), 1),
                  _createElementVNode("td", null, _toDisplayString(lora.UPLOADED_TIME), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_center, null, {
                      default: _withCtx(() => [
                        (lora.inuse)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "button",
                              style: {"height":"30px","width":"100%","margin-top":"0px","font-size":"18px","font-weight":"bold","color":"#bf4040"},
                              value: lora.ID,
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.delete_item($event)))
                            }, _toDisplayString(_ctx.$t("LoRaList.delete")), 9, _hoisted_20))
                          : _createCommentVNode("", true),
                        (lora.inuse == false && _ctx.super_user == true)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              type: "button",
                              style: {"height":"30px","width":"100%","margin-top":"0px","font-size":"18px","font-weight":"bold","color":"#37a58a"},
                              value: lora.ID,
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.restore_item($event)))
                            }, _toDisplayString(_ctx.$t("LoRaList.restore")), 9, _hoisted_21))
                          : _createCommentVNode("", true),
                        (lora.inuse == false && _ctx.super_user == false)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 2,
                              type: "button",
                              style: {"height":"30px","width":"100%","margin-top":"0px","font-size":"18px","font-weight":"bold","color":"#bf4040"},
                              value: lora.ID
                            }, _toDisplayString(_ctx.$t("LoRaList.deleted")), 9, _hoisted_22))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ], 4))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}