import { Api } from "@/api/api";
import { fetch_token } from "./utils";

export class ApiClient extends Api<unknown> {
  constructor() {
    const token = fetch_token();
    super({
      baseApiParams: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  }

  async getProjectId() {
    return this.api
      .projectControllerGetAllProjects()
      .then((response) => response.data[0].id);
  }

  async getStationId(pid: number) {
    return this.api
      .stationControllerGetStations(pid)
      .then((response) => response.data[0].id);
  }

  async getProjects() {
    return this.api
      .projectControllerGetAllProjects()
      .then((response) => response.data);
  }

  async getStations(pid: number) {
    return this.api
      .stationControllerGetStations(pid)
      .then((response) => response.data);
  }
}

export const apiClient = new ApiClient();
