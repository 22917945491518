import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  class: "w3-content w3-padding",
  style: {"max-width":"1200px"}
}
const _hoisted_2 = { style: {"float":"left","width":"50%","padding":"5px","margin-bottom":"15px"} }
const _hoisted_3 = { class: "field_name" }
const _hoisted_4 = { value: "" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { style: {"float":"left","width":"50%","padding":"5px","margin-bottom":"15px"} }
const _hoisted_7 = { class: "field_name" }
const _hoisted_8 = { value: "" }
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  style: {"font-family":"Microsoft JhengHei"},
  class: "w3-border-bottom w3-border-light-grey w3-padding-16"
}
const _hoisted_11 = { style: {"float":"left","width":"100%","padding":"5px"} }
const _hoisted_12 = { class: "field_name" }
const _hoisted_13 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_14 = { class: "field_name" }
const _hoisted_15 = { value: "" }
const _hoisted_16 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_17 = { class: "field_name" }
const _hoisted_18 = ["value"]
const _hoisted_19 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_20 = { class: "field_name" }
const _hoisted_21 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_22 = { class: "field_name" }
const _hoisted_23 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_24 = { class: "field_name" }
const _hoisted_25 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_26 = { class: "field_name" }
const _hoisted_27 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("project")) + "：", 1),
      _withDirectives(_createElementVNode("select", {
        input: "",
        class: "input_form",
        style: {"float":"left","width":"100%"},
        name: "observer",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.project_change && _ctx.project_change(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pid) = $event))
      }, [
        _createElementVNode("option", _hoisted_4, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: project.id
          }, _toDisplayString(project.name), 9, _hoisted_5))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.pid]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("station")) + "*：", 1),
      _withDirectives(_createElementVNode("select", {
        input: "",
        class: "input_form",
        style: {"float":"left","width":"100%"},
        name: "observer",
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.station_change && _ctx.station_change(...args))),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sid) = $event))
      }, [
        _createElementVNode("option", _hoisted_8, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stations, (station, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: station.id
          }, _toDisplayString(station.name), 9, _hoisted_9))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.sid]
      ])
    ]),
    _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t("StationInf.title")), 1),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("StationInf.project_name")) + "*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "project_name",
        id: "project_name",
        readonly: "readonly",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.station_inf.project_name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.station_inf.project_name]
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("StationInf.observer")) + "*：", 1),
      _withDirectives(_createElementVNode("select", {
        input: "",
        class: "input_form",
        name: "observer",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.station_inf.observer) = $event))
      }, [
        _createElementVNode("option", _hoisted_15, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.observers, (observer, i) => {
          return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(observer.name), 1))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.station_inf.observer]
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("StationInf.add_observer")) + "：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        style: {"float":"left","width":"70%"},
        type: "text",
        name: "project_name",
        id: "project_name",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.station_inf.add_observer) = $event))
      }, null, 512), [
        [_vModelText, _ctx.station_inf.add_observer]
      ]),
      _createElementVNode("input", {
        style: {"float":"left","right":"0px","height":"45px","width":"28%","font-size":"20px","margin-top":"-20px","margin-left":"1%","margin-right":"1%"},
        type: "submit",
        name: "send",
        value: _ctx.text_add,
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.add_observer()))
      }, null, 8, _hoisted_18)
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("StationInf.stn_name")) + "*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "stn_name",
        id: "stn_name",
        readonly: "readonly",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.station_inf.stn_name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.station_inf.stn_name]
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("StationInf.lat")) + "(°)*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "lat",
        id: "lat",
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.station_inf.lat) = $event))
      }, null, 512), [
        [_vModelText, _ctx.station_inf.lat]
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("StationInf.lon")) + "(°)*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "lon",
        id: "lon",
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.station_inf.lon) = $event))
      }, null, 512), [
        [_vModelText, _ctx.station_inf.lon]
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("StationInf.alt")) + "(m)*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "alt",
        id: "alt",
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.station_inf.alt) = $event))
      }, null, 512), [
        [_vModelText, _ctx.station_inf.alt]
      ])
    ]),
    _createElementVNode("input", {
      style: {"height":"60px","width":"100%","margin-top":"60px","font-size":"36px","font-weight":"bold"},
      type: "submit",
      name: "send",
      value: _ctx.text_save,
      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.submit()))
    }, null, 8, _hoisted_27)
  ]))
}