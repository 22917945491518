
import { defineComponent, ref, computed } from "vue";
import { Api, StationRequestDto } from "@/api/api";
import { apiClient } from "@/scrpts/service";
import Cookies from "js-cookie";
import moment from "moment";

export default defineComponent({
  name: "HelloWorld",
  components: {},
  data() {
    return {
      pid: 0,
      sid: 0,
      projects: [] as {
        id: number;
        name: string;
      }[],
      stations: [] as {
        id: number;
        name: string;
      }[],
      auth_token: "",
      text_save: "",
      text_add: "",
      observers: ["戴某某", "王某某", "陳某某"],
      station_inf: {
        project_name: "",
        observer: "",
        add_observer: "",
        stn_name: "",
        lat: "",
        lon: "",
        alt: "",
      },
    };
  },
  methods: {
    async page_refresh() {
      apiClient.api
        .stationControllerGetStation(this.pid, this.sid)
        .then((response) => {
          const data = response.data as { [key: string]: any };
          this.station_inf.project_name = data.project.name;
          this.station_inf.lat = data.latitude;
          this.station_inf.lon = data.longitude;
          this.station_inf.alt = data.altitude;
          this.station_inf.stn_name = data.name;
          this.observers = data.observers;
        });
    },
    async project_change() {
      const response = await apiClient.api.stationControllerGetStations(
        this.pid
      );
      this.stations = [];
      for (var i in response.data) {
        const item = {
          id: response.data[i].id,
          name: response.data[i].name,
        };
        this.stations.push(item);
      }
      try {
        this.sid = this.stations[0].id;
        this.page_refresh();
        Cookies.set("pid", String(this.pid));
        Cookies.set("sid", String(this.stations[0].id));
      } catch {
        alert("No Data!");
      }
    },
    async station_change() {
      this.page_refresh();
      Cookies.set("pid", String(this.pid));
      Cookies.set("sid", String(this.sid));
    },
    submit() {
      const data: StationRequestDto = {
        name: this.station_inf.stn_name,
        latitude: parseFloat(this.station_inf.lat),
        longitude: parseFloat(this.station_inf.lon),
        altitude: parseFloat(this.station_inf.alt),
      };
      apiClient.api
        .stationControllerUpdateStation(this.pid, this.sid, data)
        .then((response) => {
          alert(this.$t("UploadView.success"));
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          alert(this.$t("UploadView.failed"));
        });
    },
    add_observer() {
      const data = {
        name: this.station_inf.add_observer,
      };
      apiClient.api
        .observerControllerCreateObserver(this.pid, this.sid, data)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      alert("已新增使用者:" + this.station_inf.add_observer);
      this.station_inf.add_observer = "";
      this.$router.go(0);
    },
  },
  async mounted() {
    this.text_save = this.$t("StationInf.save");
    this.text_add = this.$t("StationInf.add");

    const pid = Cookies.get("pid");
    const sid = Cookies.get("sid");

    this.pid = pid !== undefined ? parseInt(pid) : 99999;
    this.sid = sid !== undefined ? parseInt(sid) : 99999;

    this.projects = await apiClient.getProjects();
    this.stations = await apiClient.getStations(this.pid);

    apiClient.api
      .stationControllerGetStation(this.pid, this.sid)
      .then((response) => {
        const data = response.data as { [key: string]: any };
        this.station_inf.project_name = data.project.name;
        this.station_inf.lat = data.latitude;
        this.station_inf.lon = data.longitude;
        this.station_inf.alt = data.altitude;
        this.station_inf.stn_name = data.name;
        this.observers = data.observers;
      });
  },
});
