import { createStore } from "vuex";

export default createStore({
  state: {
    isLoggedIn: false,
    expire_time: "",
  },
  getters: {},
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setExpireTime(state, time) {
      state.expire_time = time;
    },
  },
  actions: {
    login({ commit }, user) {
      // 在登录成功后，调用该 action 来更新状态
      commit("setLoggedIn", true);
      commit("setExpireTime", "myTime");
    },
    logout({ commit }) {
      // 在注销时，调用该 action 来更新状态
      commit("setLoggedIn", false);
      commit("setExpireTime", "XXXXX");
    },
  },
  modules: {},
});
