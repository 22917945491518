import Cookies from "js-cookie";
import moment from "moment";

export function timestampTransformer(timestamp: number): string {
  const datetime = moment.unix(timestamp / 1e3);
  return datetime.format("YYYY/MM/DD HH:mm:ss [LST]");
}

export function fetch_token() {
  const info = Cookies.get("login");
  return info ? JSON.parse(info).token : "";
}
