
import { defineComponent, ref, onUnmounted } from "vue";
import {
  Api,
  ProjectRequestDto,
  StationRequestDto,
  ProjectStatusDto,
  ProjectResponseDtoStatusEnum,
  ProjectStatusDtoStatusEnum,
} from "@/api/api";
import Cookies from "js-cookie";
import { ApiClient } from "@/scrpts/service";
import Clipboard from "clipboard";

export default defineComponent({
  name: "HelloWorld",
  components: {},
  data() {
    return {
      auth_token: "",
      station_text: ref(""),
      project_text: "",
      text_add: "",
      text_delete: "",
      project_status: "",
      project_status_: "",
      projects: [] as {
        id: number;
        name: string;
        status: string;
      }[],
      new_project: "",
      sel_project_id: 0,
      stations: [] as {
        id: number;
        name: string;
      }[],
      new_station: "",
      sel_station_id: 0,
      signup_token: ref([
        {
          name: "PM",
          role: "pm",
          token: "",
        },
        {
          name: "PA",
          role: "pa",
          token: "",
        },
        {
          name: "測站",
          role: "station",
          token: "",
        },
      ]),
    };
  },
  methods: {
    status_handler(iop: string, status: any) {
      const client = new ApiClient();
      if (status != "archived") {
        if (
          window.confirm(
            this.$t("Projectcontrol.status_warning1") +
              " " +
              iop +
              this.$t("Projectcontrol.status_warning2")
          )
        ) {
          const body = {
            status: status,
          };

          client.api
            .projectControllerSetProjectStatus(this.sel_project_id, body)
            .then((response) => {
              alert(this.$t("Projectcontrol.status_success"));
              this.$router.go(0);
            })
            .catch((error) => {
              alert(this.$t("Projectcontrol.status_failed"));
            });
        }
      }
    },
    change_status() {
      var iop = "";
      switch (this.project_status_) {
        case ProjectResponseDtoStatusEnum.Iop:
          iop = "IOP";
          this.status_handler(iop, ProjectResponseDtoStatusEnum.Iop);
          break;
        case ProjectResponseDtoStatusEnum.Postiop:
          iop = "Post-IOP";
          this.status_handler(iop, ProjectResponseDtoStatusEnum.Postiop);
          break;
        case ProjectResponseDtoStatusEnum.Archived:
          iop = "Archived";
          this.status_handler(iop, ProjectResponseDtoStatusEnum.Archived);
          break;
      }

      console.log(this.project_status_);

      // client.api.projectControllerSetProjectStatus(this.sel_project_id, )
    },
    roleUrl(role: any) {
      console.log(role.token);
      return `https://aiqc.atmos.pccu.edu.tw/register?role=${role.role}&token=${role.token}`;
    },
    validateInput() {
      this.new_station = this.new_station.replace(/[^A-Za-z0-9_]/g, "");
      this.new_project = this.new_project.replace(/[^A-Za-z0-9_]/g, "");
    },
    async project_change() {
      console.log(this.sel_project_id);

      const client = new ApiClient();

      const response = await client.api.stationControllerGetStations(
        this.sel_project_id
      );
      this.stations = [];
      for (var i in response.data) {
        const item = {
          id: response.data[i].id,
          name: response.data[i].name,
        };
        this.stations.push(item);
      }

      try {
        const response3 = await client.api.projectControllerGetProjectToken(
          this.sel_project_id
        );

        this.signup_token[0].token = response3.data.token;
        this.signup_token[1].token = response3.data.token;

        var project_index = 0;

        for (i in this.projects) {
          if (this.sel_project_id == this.projects[i].id) {
            project_index = parseInt(i);
            break;
          }
        }

        this.project_text = this.projects[project_index].name;

        console.log(this.sel_project_id);
        console.log(response3.data.token);

        this.sel_station_id = this.stations[0].id;
        const response4 = await client.api.stationControllerGetStationToken(
          this.sel_project_id,
          this.stations[0].id
        );

        Cookies.set("pid", String(this.sel_project_id));
        Cookies.set("sid", String(this.stations[0].id));

        this.signup_token[2].token =
          response4.data.token + "&station=" + this.stations[0].name;
      } catch {
        alert("empty!");
        Cookies.set("pid", String(this.sel_project_id));
        project_index = 0;

        for (i in this.projects) {
          if (this.sel_project_id == this.projects[i].id) {
            project_index = parseInt(i);
            break;
          }
        }
        this.signup_token[2].token = "";
      }

      for (i in this.projects) {
        if (this.sel_project_id == this.projects[i].id) {
          switch (this.projects[i].status) {
            case ProjectResponseDtoStatusEnum.Preiop:
              this.project_status_ = ProjectResponseDtoStatusEnum.Iop;
              this.project_status = "To --> IOP";
              break;
            case ProjectResponseDtoStatusEnum.Iop:
              this.project_status_ = ProjectResponseDtoStatusEnum.Postiop;
              this.project_status = "To --> Post-IOP";
              break;
            case ProjectResponseDtoStatusEnum.Postiop:
              this.project_status_ = ProjectResponseDtoStatusEnum.Archived;
              this.project_status = "To --> Archived";
              break;
            case ProjectResponseDtoStatusEnum.Archived:
              this.project_status = "Archived";
              break;
          }
          break;
        }
      }
    },
    async station_change() {
      const client = new ApiClient();
      const params = {
        headers: {
          Authorization: `Bearer ${this.auth_token}`,
        },
      };

      try {
        const response3 = await client.api.projectControllerGetProjectToken(
          this.sel_project_id
        );

        this.signup_token[0].token = response3.data.token;
        this.signup_token[1].token = response3.data.token;

        const response4 = await client.api.stationControllerGetStationToken(
          this.sel_project_id,
          this.sel_station_id
        );
        Cookies.set("pid", String(this.sel_project_id));
        Cookies.set("sid", String(this.sel_station_id));

        var station_text = "";

        for (var i in this.stations) {
          if (this.stations[i].id == this.sel_station_id) {
            station_text = this.stations[i].name;
          }
        }

        this.signup_token[2].token =
          response4.data.token + "&station=" + station_text;
      } catch {
        alert("empty!");
        this.signup_token[2].token = "";
      }
    },
    add_project() {
      const client = new ApiClient();
      const params = {
        headers: {
          Authorization: `Bearer ${this.auth_token}`,
        },
      };
      const data: ProjectRequestDto = {
        name: this.new_project,
      };
      client.api
        .projectControllerCreateProject(data)
        .then((response) => {
          Cookies.remove("sid");
          alert(this.$t("Projectcontrol.success"));
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
          alert(this.$t("Projectcontrol.faild"));
        });
    },
    add_station() {
      const client = new ApiClient();
      const params = {
        headers: {
          Authorization: `Bearer ${this.auth_token}`,
        },
      };
      const data: StationRequestDto = {
        name: this.new_station,
        latitude: 0.0,
        longitude: 0.0,
        altitude: 0,
      };

      client.api
        .stationControllerCreateStation(this.sel_project_id, data)
        .then((response) => {
          Cookies.set("pid", String(this.sel_project_id));
          Cookies.remove("sid");
          alert(this.$t("Projectcontrol.success"));
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
          alert(this.$t("Projectcontrol.faild"));
        });
    },
    delete_project() {
      // const client = new Api();
      const client = new ApiClient();
      var project_text = "";
      for (var i in this.projects) {
        if (this.projects[i].id == this.sel_project_id) {
          project_text = this.projects[i].name;
        }
      }

      if (
        window.confirm(
          this.$t("Projectcontrol.del_warning") + " " + project_text
        )
      ) {
        client.api
          .projectControllerDeleteProject(this.sel_project_id)
          .then((response) => {
            alert(this.$t("Projectcontrol.del_success"));
            Cookies.remove("pid");
            Cookies.remove("sid");
            this.$router.go(0);
          })
          .catch((error) => {
            console.log(this.sel_project_id);
            console.log(error);
            alert(this.$t("Projectcontrol.del_faild"));
          });
      }
    },
    delete_station() {
      const client = new ApiClient();

      var station_text = "";
      for (var i in this.stations) {
        if (this.stations[i].id == this.sel_station_id) {
          station_text = this.stations[i].name;
        }
      }

      if (
        window.confirm(
          this.$t("Projectcontrol.del_warning") + " " + station_text
        )
      ) {
        client.api
          .stationControllerDeleteStation(
            this.sel_project_id,
            this.sel_station_id
          )
          .then((response) => {
            alert(this.$t("Projectcontrol.del_success"));
            Cookies.set("pid", String(this.sel_project_id));
            Cookies.remove("sid");
            this.$router.go(0);
          })
          .catch((error) => {
            console.log(error);
            alert(this.$t("Projectcontrol.del_faild"));
          });
      }
    },
  },
  directives: {
    copyText: {
      mounted(el, binding) {
        const clipboard = new Clipboard(el, {
          text: () => binding.value(),
        });

        clipboard.on("success", () => {
          alert("Copied");
        });

        clipboard.on("error", () => {
          alert("Failed");
          clipboard.destroy();
        });

        onUnmounted(() => {
          clipboard.destroy();
        });
      },
    },
  },
  async mounted() {
    const pid = Cookies.get("pid");
    const sid = Cookies.get("sid");

    this.text_add = this.$t("Projectcontrol.add");
    this.text_delete = this.$t("Projectcontrol.delete");
    this.signup_token[2].name = this.$t("Projectcontrol.station2");

    this.sel_project_id = pid !== undefined ? parseInt(pid) : 99999;
    this.sel_station_id = sid !== undefined ? parseInt(sid) : 99999;

    const client = new ApiClient();

    try {
      // 取得 Project ID
      const response1 = await client.api.projectControllerGetAllProjects();

      for (var i in response1.data) {
        console.log(response1.data[i]);
        const item = {
          id: response1.data[i].id,
          name: response1.data[i].name,
          status: response1.data[i].status,
        };
        this.projects.push(item);
      }

      // 若 project id 不存在
      if (this.sel_project_id == 99999) {
        this.sel_project_id = this.projects[0].id;
      }

      const response2 = await client.api.stationControllerGetStations(
        this.sel_project_id
      );

      for (i in response2.data) {
        console.log(response2.data[i]);
        const item = {
          id: response2.data[i].id,
          name: response2.data[i].name,
        };
        this.stations.push(item);

        // 若 station id 不存在
        if (this.sel_station_id == 99999) {
          this.sel_station_id = this.stations[0].id;
        }

        const response3 = await client.api.projectControllerGetProjectToken(
          this.sel_project_id
        );

        const response4 = await client.api.stationControllerGetStationToken(
          this.sel_project_id,
          this.sel_station_id
        );

        var project_index = 0;
        var station_index = 0;

        for (i in this.projects) {
          if (this.sel_project_id == this.projects[i].id) {
            project_index = parseInt(i);
            break;
          }
        }

        this.project_text = this.projects[project_index].name;

        for (i in this.stations) {
          if (this.sel_station_id == this.stations[i].id) {
            station_index = parseInt(i);
            break;
          }
        }

        this.signup_token[0].token = response3.data.token;
        this.signup_token[1].token = response3.data.token;
        this.signup_token[2].token =
          response4.data.token +
          "&station=" +
          this.stations[station_index].name;
      }
    } catch (error) {
      console.log(error);
    }

    for (i in this.projects) {
      if (this.sel_project_id == this.projects[i].id) {
        switch (this.projects[i].status) {
          case ProjectResponseDtoStatusEnum.Preiop:
            this.project_status_ = ProjectResponseDtoStatusEnum.Iop;
            this.project_status = "To --> IOP";
            break;
          case ProjectResponseDtoStatusEnum.Iop:
            this.project_status_ = ProjectResponseDtoStatusEnum.Postiop;
            this.project_status = "To --> Post-IOP";
            break;
          case ProjectResponseDtoStatusEnum.Postiop:
            this.project_status_ = ProjectResponseDtoStatusEnum.Archived;
            this.project_status = "To --> Archived";
            break;
          case ProjectResponseDtoStatusEnum.Archived:
            this.project_status_ = ProjectResponseDtoStatusEnum.Archived;
            this.project_status = "Archived";
            break;
        }
        break;
      }
    }
  },
});
