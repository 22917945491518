
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";

export default defineComponent({
  mounted() {
    Cookies.remove("login");
    Cookies.remove("locale");
    Cookies.remove("pid");
    Cookies.remove("sid");
    const router = useRouter();
    router.push("/login");
  },
});
