import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w3-content w3-padding",
  style: {"max-width":"1200px"}
}
const _hoisted_2 = {
  style: {"font-family":"Microsoft JhengHei"},
  class: "w3-border-bottom w3-border-light-grey w3-padding-16"
}
const _hoisted_3 = { style: {"float":"left","width":"100%","padding":"5px"} }
const _hoisted_4 = { class: "field_name" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { style: {"float":"left","width":"100%","padding":"5px"} }
const _hoisted_7 = { class: "field_name" }
const _hoisted_8 = { style: {"float":"left","width":"100%","padding":"5px"} }
const _hoisted_9 = { class: "field_name" }
const _hoisted_10 = { style: {"float":"left","width":"100%","padding":"5px"} }
const _hoisted_11 = { class: "field_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("Register.title")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Register.role")) + "：", 1),
      _createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "project_name",
        id: "project_name",
        value: _ctx.role_text,
        readonly: "readonly"
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("Register.user_name")) + "*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "text",
        name: "project_name",
        id: "project_name",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user) = $event))
      }, null, 512), [
        [_vModelText, _ctx.user]
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("Register.password")) + "*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "password",
        name: "password",
        id: "password",
        placeholder: "Password",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pass) = $event))
      }, null, 512), [
        [_vModelText, _ctx.pass]
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("Register.password_confirm")) + "*：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        type: "password",
        name: "password",
        id: "password",
        placeholder: "Password",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pass_chk) = $event))
      }, null, 512), [
        [_vModelText, _ctx.pass_chk]
      ])
    ]),
    _createElementVNode("input", {
      style: {"height":"60px","width":"100%","margin-top":"60px","font-size":"36px","font-weight":"bold"},
      type: "submit",
      name: "send",
      value: "註冊/Register",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
    })
  ]))
}