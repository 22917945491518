import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w3-content w3-padding out_div",
  style: {"position":"relative","max-width":"1200px","margin-bottom":"-10px","width":"100%","background-color":"#ededed"}
}
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = {
  class: "text_font_size_status",
  style: {"float":"left"}
}
const _hoisted_4 = {
  class: "text_font_size_status",
  style: {"float":"left"}
}
const _hoisted_5 = {
  class: "text_font_size_status",
  style: {"float":"left"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isLogged)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, "現在階段:" + _toDisplayString(_ctx.status), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, "身分:" + _toDisplayString(_ctx.role), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, "使用者:" + _toDisplayString(_ctx.user), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}