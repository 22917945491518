
import { apiClient } from "@/scrpts/service";
import { HttpStatusCode } from "axios";
import moment from "moment";
import { defineComponent, ref, computed } from "vue";
import { AiqcRecordResponseStatusEnum as StatusEnum } from "@/api/api";
import Cookies from "js-cookie";

export default defineComponent({
  methods: {
    show_all_check() {
      if (this.show_all == true) {
        Cookies.set("show_all", "0");
      } else {
        Cookies.set("show_all", "1");
      }
      this.$router.go(0);
    },
    async page_refresh() {
      // 取得ST
      const query = {
        all: this.show_all,
      };

      const sts = await apiClient.api
        .aiqcControllerGetAiqcRecord(this.pid, this.sid, query)
        .then((response) => {
          return response.data;
        });

      this.st_list = sts.map((st) => ({
        ST_ID: st.stId,
        STATUS: st.status,
        NOTE: String(st.message),
      }));
    },
    async project_change() {
      const response = await apiClient.api.stationControllerGetStations(
        this.pid
      );
      this.stations = [];
      for (var i in response.data) {
        const item = {
          id: response.data[i].id,
          name: response.data[i].name,
        };
        this.stations.push(item);
      }
      try {
        this.sid = this.stations[0].id;
        this.page_refresh();
        Cookies.set("pid", String(this.pid));
        Cookies.set("sid", String(this.stations[0].id));
      } catch {
        alert("No Data!");
      }
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
      );
      this.update_time = dateTimeStr.value;
    },
    async station_change() {
      this.page_refresh();
      Cookies.set("pid", String(this.pid));
      Cookies.set("sid", String(this.sid));
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
      );
      this.update_time = dateTimeStr.value;
    },
    download() {
      var sts = [];
      for (var i in this.checkedItems) {
        sts.push(parseInt(this.checkedItems[i]));
        console.log(parseInt(this.checkedItems[i]));
      }

      const body = {
        stids: sts,
      };

      apiClient.api
        .aiqcControllerPostDownloadRequest(this.pid, this.sid, body)
        .then((response) => {
          if (response.status == HttpStatusCode.Created)
            alert(this.$t("Download.Processing_alert"));
          else alert(this.$t("Download.faild_alert") + ` (${response.status})`);
          this.$router.push({
            name: "download_list",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  data() {
    return {
      pid: 0,
      sid: 0,
      show_all_num: 0,
      show_all: false,
      projects: [] as {
        id: number;
        name: string;
      }[],
      stations: [] as {
        id: number;
        name: string;
      }[],
      columns: {
        SEL: "選取",
        ST_ID: "ST ID",
        STATUS: "狀態",
        NOTE: "備註",
      },
      st_list: [] as {
        ST_ID: number;
        STATUS: StatusEnum;
        NOTE: string;
      }[],
      checkedItems: [],
      update_time: moment().format("YYYY/MM/DD HH:mm:ss [LST]"),
      i18n_status: new Map<
        StatusEnum,
        {
          text: string;
          color: string;
        }
      >(),
      text_Nodata: "",
      text_Pending: "",
      text_Processing: "",
      text_Error: "",
      text_Complete: "",
    };
  },
  async mounted() {
    this.i18n_status.set(StatusEnum.Nodata, {
      text: this.$t("Download.Nodata"),
      color: "#bf4040",
    });
    this.i18n_status.set(StatusEnum.Pending, {
      text: this.$t("Download.Pending"),
      color: "#b8974b",
    });
    this.i18n_status.set(StatusEnum.Processing, {
      text: this.$t("Download.Processing"),
      color: "#b8974b",
    });
    this.i18n_status.set(StatusEnum.Error, {
      text: this.$t("Download.Error"),
      color: "#bf4040",
    });
    this.i18n_status.set(StatusEnum.Complete, {
      text: this.$t("Download.Complete"),
      color: "#4bb885",
    });

    try {
      const pid = Cookies.get("pid");
      const sid = Cookies.get("sid");
      const show_all_num = Cookies.get("show_all");

      this.pid = pid !== undefined ? parseInt(pid) : 99999;
      this.sid = sid !== undefined ? parseInt(sid) : 99999;
      this.show_all_num =
        show_all_num !== undefined ? parseInt(show_all_num) : 0;

      if (this.show_all_num == 0) {
        this.show_all = false;
      } else {
        this.show_all = true;
      }

      this.projects = await apiClient.getProjects();
      this.stations = await apiClient.getStations(this.pid);

      const query = {
        all: this.show_all,
      };

      // 取得ST
      const sts = await apiClient.api
        .aiqcControllerGetAiqcRecord(this.pid, this.sid, query)
        .then((response) => {
          return response.data;
        });

      this.st_list = sts.map((st) => ({
        ST_ID: st.stId,
        STATUS: st.status,
        NOTE: String(st.message),
      }));
    } catch (error) {
      console.log(error);
    }
  },
});
