
import Cookies from "js-cookie";
import { defineComponent } from "vue";
import { computed, ref } from "vue";
import {
  Api,
  SignInRequestDto,
  UploadAttachmentFilesRequestDto,
  AttachmentControllerUploadLaunchAttachementsParamsTypeEnum as FileEnum,
} from "@/api/api";
import axios, { AxiosResponse, AxiosError } from "axios";

type Upload_Files_Obj = {
  image: {
    type: FileEnum;
    files: File[];
  };
  edt: {
    type: FileEnum;
    files: File[];
  };
};

export default defineComponent({
  data() {
    return {
      pid: 0,
      sid: 0,
      launch_id: 0,
      auth_token: "",
      text_upload_button: "",
      pic_inputFile: ref<File[] | undefined>(undefined),
      edt_inputFile: ref<File[] | undefined>(undefined),
      upload_files: ref<Upload_Files_Obj>({
        image: {
          type: FileEnum.Image,
          files: [],
        },
        edt: {
          type: FileEnum.Edt,
          files: [],
        },
      }),
    };
  },
  methods: {
    fileChange(event: any) {
      const htmlId = event.target.id;
      const files = event.target.files as { [name: string]: File };
      const filelist = [];

      switch (htmlId) {
        case "pic_file":
          this.upload_files.image.files = Object.values(files);
          // this.pic_inputFile = Object.values(files).map((file) => file);
          break;
        case "colaunch_file":
          this.upload_files.edt.files = Object.values(files);
          // this.edt_inputFile = Object.values(files);
          break;
      }
    },
    upload_file() {
      const info = Cookies.get("login");
      var token = "";
      var upload_success = true;
      if (info) {
        token = JSON.parse(info).token;
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      for (const [key, obj] of Object.entries(this.upload_files)) {
        //console.log(key, obj);
        const { type, files } = obj;

        // TODO: 要找個翻譯區放
        const i18n = new Map<FileEnum, string>();
        i18n.set(FileEnum.Image, this.$t("picture"));
        i18n.set(FileEnum.Edt, this.$t("edt"));

        const formData = new FormData();
        files.forEach((file) => formData.append("files", file));
        // TODO: 我記得有個 query 的變數可以用，但目前這樣寫應該也沒差
        axios
          .post(
            `/api/projects/${this.pid}/stations/${this.sid}/launches/${this.launch_id}/attachments?type=${type}`,
            formData,
            {
              headers,
            }
          )
          .then((response) => {
            alert(`${i18n.get(type)} ` + this.$t("success"));
          })
          .catch((error) => {
            console.error(error);
            alert(`${i18n.get(type)} ` + this.$t("failed"));
            upload_success = false;
          });

        if (upload_success) {
          this.$router.push({
            name: "home",
          });
        }
      }
    },
  },
  async mounted() {
    this.text_upload_button = this.$t("AttchUpload.upload");
    const selectedLaunchFromRoute = this.$route.query.selected_launch;
    this.launch_id =
      selectedLaunchFromRoute !== null
        ? Number(selectedLaunchFromRoute)
        : 99999;

    const pid = Cookies.get("pid");
    const sid = Cookies.get("sid");

    this.pid = pid !== undefined ? parseInt(pid) : 99999;
    this.sid = sid !== undefined ? parseInt(sid) : 99999;
  },
});
