
import Cookies from "js-cookie";
import { defineComponent } from "vue";
import { Api, SignInRequestDto } from "@/api/api";

export default defineComponent({
  data() {
    return {
      user_password: "",
      loginForm: {
        username: "",
        token: "",
      },
    };
  },
  methods: {
    handleLogin() {
      let username_ = this.loginForm.username;
      let password_ = this.user_password;
      // 帳號密碼需驗證不能為空
      if (username_ !== "" && password_ !== "") {
        const client = new Api();
        const login_req: SignInRequestDto = {
          username: username_,
          password: password_,
        };
        client.api
          .authControllerSingIn(login_req)
          .then((response) => {
            this.loginForm.token = response.data.access_token;
            Cookies.set("login", JSON.stringify(this.loginForm), {
              expires: 1,
            });
            // console.log(JSON.stringify(this.loginForm));
            // cookie當中有token被設置才能改變路由
            if (Cookies.get("login") && this.loginForm.token) {
              this.$router.push({ name: "home" });
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.status === 401) {
              alert("密碼錯誤!!");
            }
          });
      } else {
        alert("帳號密碼不能為空");
      }
    },

    // 將Cookies清除的測試用button事件
    removeCookie() {
      Cookies.remove("login");
    },
  },
});
