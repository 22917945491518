import { createI18n, I18nOptions } from "vue-i18n";
import message from "./common.json";
import Cookies from "js-cookie";

let locale = "" as string | undefined;

if (!Cookies.get("locale")) {
  Cookies.set("locale", "zh-TW");
}
locale = Cookies.get("locale");

const i18nOptions: I18nOptions = {
  locale: locale,
  messages: message,
  fallbackLocale: locale,
};

const i18n = new (createI18n as any)(i18nOptions);
console.log(i18n);

export default i18n;
