<template>
  <div class="w3-content w3-padding" style="max-width: 1200px">
    <div
      class="w3-col l12 m12"
      style="
        margin-right: -5px;
        padding-left: 10px;
        padding-right: 10px;
        z-index: -9;
        margin-bottom: 0px;
      "
    >
      <div
        class="w3-col l12 m12"
        style="
          margin-right: -5px;
          padding-left: 10px;
          padding-right: 10px;
          z-index: -9;
          margin-bottom: 0px;
        "
      >
        <h1 style="margin-bottom: -15px">AIQC使用說明(簡易版)</h1>

        <br />
        <h2>1.帳號管理</h2>
        <h3>(1)帳號使用原則</h3>
        <p>
          本平台以觀測計畫為帳號管理之依據，系統視每一觀測計畫內所申請之帳號為獨立帳號，不同計畫間帳號不能通用。單一觀測實驗需通過email申請計畫開通，須提供密集觀測實驗之英文名稱縮寫與規劃之密集觀測時間。平台在審核相關計畫需求後，通過審核之計畫將開通該計畫項目之權限，同時提供一個計畫管理者(Project
          manager, PM)帳號申請連結。計畫管理者可透過平台取得申請計畫助理(project
          assistant,
          PA)帳號與測站(station)帳號之申請連結，每一測站使用同一帳號登入。在觀測實驗結束後，帳號會持續保留一段時間，但在該計畫專案在系統移除後也同步移除。
        </p>

        <h3>(2) 觀測計畫申請</h3>
        <p>
          每一觀測實驗計畫之帳號視為一群組，計畫主持人需在計畫執行前透過email申請相關帳號權限。信件內容須包含以下資訊:
        </p>
        <p>____________________________________________________</p>
        <p>title: Apply for permission to use AIQC platform</p>
        <p>
          Project name: <span style="color: rgb(255, 53, 53)">YESR2023</span>
        </p>
        <p>
          Intensive observation time:
          <span style="color: rgb(255, 53, 53)">11/15~12/20</span>
        </p>
        <p>(rough estimate is sufficient)</p>
        <p>
          Name of the host of the observation experiment (project manager, PM):
          <span style="color: rgb(255, 53, 53)">Shih-Hao Su</span>
        </p>
        <p>PM contact information:</p>
        <p>
          Affiliation:
          <span style="color: rgb(255, 53, 53)">
            Department of Atmospheric Sciences, Chinese Culture University,
            Taiwan
          </span>
        </p>
        <p>
          Email: <span style="color: rgb(255, 53, 53)">ssh3@g.pccu.edu.tw</span>
        </p>
        <p></p>

        <h3>(3) PM, PA, Station帳號申請</h3>
        <p>
          在審核通過後，PM將會收到申請通過之回信，並在信件中會附上含token的開通PM帳號申請連結。信件範例如下：
        </p>
        <img style="width: 100%" src="@/assets/img/manual/1.png" />
        <p>
          請注意，該連結只能開通一組PM帳號，為維護實驗資料之安全性與平台資安，請勿外流申請。PM在完成帳號申請並登入後，將可在計畫管理部分取得該計畫之註冊連結，如下圖：
        </p>
        <img style="width: 100%" src="@/assets/img/manual/2.png" />
        <p>
          PM可分享註冊連結給協助實驗計畫計畫之助理(project assistant,
          PA)，但請注意PA權限與PM具有相同之權限，可進行相關測站或資料的刪除，為維護資料之安全請審慎授權PA帳號。
          在建立PM and/or
          PA帳號後，請先透過計畫管理介面新增測站。注意測站只能使用英文命名，並避免使用特殊符號。建立測站後，需先選擇測站後才會產生測站之註冊連結，並可提供給測站使用者使用。
        </p>
        <img style="width: 100%" src="@/assets/img/manual/3.png" />

        <h3>(4) 不同階段之帳號權限</h3>
        <p>
          對應觀測實驗進行，我們將實驗區分為不同階段。分別是Pre-IOP,
          IOP,Post-IOP,
          Archive-stage，而各等級之帳號使用權限也會進行調整。相關權限如下:
        </p>
        <table border="1" class="black-border-table">
          <thead>
            <tr>
              <th></th>
              <th>Pre-IOP</th>
              <th>IOP</th>
              <th>Post-IOP</th>
              <th>Archive-stage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h4>PM</h4>
                <p>(Accessing all stations’ data within the project)</p>
              </td>
              <td>
                <p>● Generating account-link</p>
                <p>● Adding stations</p>
                <p>● Editing station information</p>
                <p>● Adding operators list</p>
                <p>● Monitoring</p>
              </td>
              <td>
                <p>● Generating account-link</p>
                <p>● Adding stations</p>
                <p>● Adding operators list</p>
                <p>● Monitoring</p>
                <p>● Uploading data</p>
                <p>● Deleting data</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
              <td>
                <p>● Monitoring</p>
                <p>● Uploading data</p>
                <p>● Deleting data</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
              <td>
                <p>● Monitoring</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
            </tr>
            <tr>
              <td>
                <h4>PA</h4>
                <p>(Accessing all stations’ data within the project)</p>
              </td>
              <td>
                <p>● Generating station account-link</p>
                <p>● Adding stations</p>
                <p>● Editing station information</p>
                <p>● Adding operators list</p>
                <p>● Monitoring</p>
              </td>
              <td>
                <p>● Generating station account-link</p>
                <p>● Adding stations</p>
                <p>● Adding operators list</p>
                <p>● Monitoring</p>
                <p>● Editing station information</p>
                <p>● Uploading data</p>
                <p>● Deleting data</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
              <td>
                <p>● Monitoring</p>
                <p>● Uploading data</p>
                <p>● Deleting data</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
              <td>
                <p>● Monitoring</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
            </tr>
            <tr>
              <td>
                <h4>Station</h4>
                <p>(Only can access one station data)</p>
              </td>
              <td>
                <p>● Editing station information</p>
                <p>● Adding operators list</p>
                <p>● Monitoring</p>
              </td>
              <td>
                <p>● Editing station information</p>
                <p>● Adding operators list</p>
                <p>● Monitoring</p>
                <p>● Uploading data</p>
                <p>● Deleting data</p>
                <p>● Running AIQC</p>
                <p>● Downloading data</p>
                <p style="color: #aaaaaa">● Re-process all data</p>
              </td>
              <td>
                <p>● Downloading data</p>
              </td>
              <td>
                <p>● Downloading data</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>＊灰色文字代表該功能目前尚未開放</p>

        <h2>2. 密集觀測實驗進行前</h2>
        <h3>(1) 創建測站</h3>
        <img style="width: 100%" src="@/assets/img/manual/4.png" />
        <img style="width: 100%" src="@/assets/img/manual/5.png" />
        <img style="width: 100%" src="@/assets/img/manual/6.png" />

        <h3>(2) 註冊「測站」帳號</h3>
        <img style="width: 100%" src="@/assets/img/manual/7.png" />
        <img style="width: 100%" src="@/assets/img/manual/8.png" />
        <img style="width: 100%" src="@/assets/img/manual/9.png" />

        <h3>(3) 首次登入</h3>
        <img style="width: 100%" src="@/assets/img/manual/10.png" />
        <img style="width: 100%" src="@/assets/img/manual/11.png" />

        <h3>(4) 新增觀測人員</h3>
        <img style="width: 100%" src="@/assets/img/manual/12.png" />

        <h3>(5) 修改測站資訊</h3>
        <img style="width: 100%" src="@/assets/img/manual/13.png" />

        <h2>3. 密集觀測實驗期間</h2>
        <h3>(1) 新增ST任務</h3>
        <img style="width: 100%" src="@/assets/img/manual/14.png" />
        <img style="width: 100%" src="@/assets/img/manual/15.png" />
        <img style="width: 100%" src="@/assets/img/manual/16.png" />

        <h3>(2) 修改/新增附件</h3>
        <img style="width: 100%" src="@/assets/img/manual/17.png" />
        <img style="width: 100%" src="@/assets/img/manual/18.png" />
        <img style="width: 100%" src="@/assets/img/manual/19.png" />
        <img style="width: 100%" src="@/assets/img/manual/20.png" />

        <h3>(3) LoRa上傳</h3>
        <img style="width: 100%" src="@/assets/img/manual/21.png" />
        <img style="width: 100%" src="@/assets/img/manual/22.png" />

        <h3>(4) 下載AIQC後結果</h3>
        <img style="width: 100%" src="@/assets/img/manual/23.png" />
        <img style="width: 100%" src="@/assets/img/manual/24.png" />
        <img style="width: 100%" src="@/assets/img/manual/25.png" />

        <h2>4. AIQC 校正後資料說明</h2>
        <img style="width: 100%" src="@/assets/img/manual/26.png" />

        <div
          class="w3-display-container"
          style="position: relative; width: 100%"
        >
          <img
            src="@/assets/img/EWaC.png"
            style="position: absolute; height: 200px; width: 200px; right: 0px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css">
/* 將指定 class 的表格外框顏色設為黑色 */
.black-border-table {
  border-collapse: collapse;
  border: 1px solid black;
}

/* 將表格頭和內容的邊框設為黑色 */
.black-border-table th,
.black-border-table td {
  border: 1px solid black;
  padding: 8px; /* 可以添加一些內邊距使得內容與邊框之間有空間 */
}
</style>
