/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SignInRequestDto {
  /**
   * 使用者名稱
   * @example "john"
   */
  username: string;
  /**
   * 使用者密碼
   * @example "thisIsMyPassword"
   */
  password: string;
}

export interface SignInResponseDto {
  /** JWT Token */
  access_token: string;
}

export interface SignUpRequestDto {
  /**
   * 使用者名稱
   * @example "john"
   */
  username: string;
  /**
   * 使用者密碼
   * @example "thisIsMyPassword"
   */
  password: string;
  /** 註冊用 token */
  token: string;
  /** 身分別 */
  role: SignUpRequestDtoRoleEnum;
}

export interface BasicResponseDto {
  /** @example "success" */
  message: string;
}

export interface UserProfileResponseDto {
  uid: number;
  username: string;
  /** @default "anonymous" */
  role: UserProfileResponseDtoRoleEnum;
}

export interface UserPasswordUpdateDto {
  /** 原本的密碼 */
  originalPassword: string;
  /** 新的密碼 */
  newPassword: string;
}

export interface UserResetDto {
  /** 愈重設的使用者 id */
  uid: number;
}

export interface UserPasswordResetResponseDto {
  /** 新的密碼 */
  password: string;
}

export interface StationWithoutRelationDto {
  id: number;
  /** @example "yilan" */
  name: string;
  /** @example 23.12 */
  latitude: number;
  /** @example 121.05 */
  longitude: number;
  /** @example 76 */
  altitude: number;
}

export interface ProjectResponseDto {
  id: number;
  /** @example "YESR_2023" */
  name: string;
  /** @example "preiop" */
  status: ProjectResponseDtoStatusEnum;
  stations: StationWithoutRelationDto[];
}

export interface ProjectRequestDto {
  /** @example "YESR_2023" */
  name: string;
}

export interface ProjectStatusDto {
  status: ProjectStatusDtoStatusEnum;
}

export interface ProjectTokenResponseDto {
  id: number;
  /** @example "cd2678f47c9e881f10643ca49e87c28e" */
  token: string;
}

export interface StationRequestDto {
  /** @example "yilan" */
  name: string;
  /** @example 23.12 */
  latitude: number;
  /** @example 121.05 */
  longitude: number;
  /** @example 76 */
  altitude: number;
}

export interface ProjectResponseWithoutStationDto {
  id: number;
  /** @example "YESR_2023" */
  name: string;
  /** @example "preiop" */
  status: ProjectResponseWithoutStationDtoStatusEnum;
}

export type Observer = object;

export interface StationEntityDto {
  id: number;
  /** @example "yilan" */
  name: string;
  /** @example 23.12 */
  latitude: number;
  /** @example 121.05 */
  longitude: number;
  /** @example 76 */
  altitude: number;
  project: ProjectResponseWithoutStationDto;
  observers: Observer[];
}

export interface StationTokenResponseDto {
  id: number;
  /** @example "cd2678f47c9e881f10643ca49e87c28e" */
  token: string;
}

export interface StationRequestPartialDto {
  /** @example "yilan" */
  name?: string;
  /** @example 23.12 */
  latitude?: number;
  /** @example 121.05 */
  longitude?: number;
  /** @example 76 */
  altitude?: number;
}

export interface GetLoRaResponseDto {
  id: number;
  /** @example "20211031_082409" */
  loratime: string;
  /** @example "LoRa_20211031_082409(2).csv" */
  originalname: string;
  /** @default true */
  inUse: boolean;
  /** @example 1693986979 */
  upload_at: number;
}

export interface UploadLoraFileRequestDto {
  /** @format binary */
  file: File;
}

export interface LoraFilePutDto {
  /** @example true */
  inUse: boolean;
  /** @example false */
  forced?: boolean;
}

export interface ObserverCreateRequestDto {
  /** @example "王曉明" */
  name: string;
}

export interface AttachmentResponseDto {
  id: number;
  /** @example "20230908_0001.jpg" */
  originalname: string;
  /** @example "image" */
  type: string;
  /** @example 1694108489 */
  upload_at: number;
}

export interface LaunchMetaWithAttDto {
  /**
   * Launch Id
   * @example 0
   */
  id: number;
  /**
   * StormTacker Id
   * @example 3304
   */
  stId: number;
  /**
   * Launch Timestamp
   * @example 1694085049
   */
  launch_timestamp: number;
  note1?: string;
  note2?: string;
  /**
   * Station Latitude
   * @example 23.5
   */
  latitude: number;
  /**
   * Station Longitude
   * @example 121.5
   */
  longitude: number;
  /**
   * Station Altitude
   * @example 72
   */
  altitude: number;
  /**
   * StormTacker Pressure (hPa)
   * @example 1010.12
   */
  st_pres?: number;
  /**
   * StormTacker Temperature (oC)
   * @example 25.12
   */
  st_temp?: number;
  /**
   * StormTacker Relative Humidity (%)
   * @example 50.01
   */
  st_rh?: number;
  /**
   * Reference Pressure (hPa)
   * @example 1010.12
   */
  ref_pres?: number;
  /**
   * Reference Temperature (oC)
   * @example 25.12
   */
  ref_temp?: number;
  /**
   * Reference Relative Humidity (%)
   * @example 50.01
   */
  ref_rh?: number;
  /**
   * Observation Pressure (hPa)
   * @example 1010.12
   */
  obs_pres?: number;
  /**
   * Observation Temperature (oC)
   * @example 25.12
   */
  obs_temp?: number;
  /**
   * Observation Relative Humidity (%)
   * @example 50.01
   */
  obs_rh?: number;
  /**
   * Observation Wind Speed (m/s)
   * @example 5.12
   */
  obs_ws?: number;
  /**
   * Observation Wind Direction (o)
   * @example 320
   */
  obs_wd?: number;
  /**
   * Observation Cloud Cover
   * @example "10"
   */
  obs_cloud?: string;
  /**
   * Observation Weather
   * @example "晴"
   */
  obs_weather?: string;
  attachments?: AttachmentResponseDto[];
}

export interface ObserverOnlyDto {
  id: number;
  /** @example "王曉明" */
  name: string;
}

export interface LaunchMetaDto {
  /**
   * Launch Id
   * @example 0
   */
  id: number;
  /**
   * StormTacker Id
   * @example 3304
   */
  stId: number;
  /**
   * Launch Timestamp
   * @example 1694085049
   */
  launch_timestamp: number;
  note1?: string;
  note2?: string;
  /**
   * Station Latitude
   * @example 23.5
   */
  latitude: number;
  /**
   * Station Longitude
   * @example 121.5
   */
  longitude: number;
  /**
   * Station Altitude
   * @example 72
   */
  altitude: number;
  /**
   * StormTacker Pressure (hPa)
   * @example 1010.12
   */
  st_pres?: number;
  /**
   * StormTacker Temperature (oC)
   * @example 25.12
   */
  st_temp?: number;
  /**
   * StormTacker Relative Humidity (%)
   * @example 50.01
   */
  st_rh?: number;
  /**
   * Reference Pressure (hPa)
   * @example 1010.12
   */
  ref_pres?: number;
  /**
   * Reference Temperature (oC)
   * @example 25.12
   */
  ref_temp?: number;
  /**
   * Reference Relative Humidity (%)
   * @example 50.01
   */
  ref_rh?: number;
  /**
   * Observation Pressure (hPa)
   * @example 1010.12
   */
  obs_pres?: number;
  /**
   * Observation Temperature (oC)
   * @example 25.12
   */
  obs_temp?: number;
  /**
   * Observation Relative Humidity (%)
   * @example 50.01
   */
  obs_rh?: number;
  /**
   * Observation Wind Speed (m/s)
   * @example 5.12
   */
  obs_ws?: number;
  /**
   * Observation Wind Direction (o)
   * @example 320
   */
  obs_wd?: number;
  /**
   * Observation Cloud Cover
   * @example "10"
   */
  obs_cloud?: string;
  /**
   * Observation Weather
   * @example "晴"
   */
  obs_weather?: string;
  /** Project Name */
  project: ProjectResponseWithoutStationDto;
  /** Station Name */
  station: StationWithoutRelationDto;
  /** Observer name */
  observer: ObserverOnlyDto;
  attachments?: AttachmentResponseDto[];
}

export interface UploadLaunchMetaRequestDto {
  /**
   * StormTacker Id
   * @example 3304
   */
  stId: number;
  /**
   * Launch Timestamp
   * @example 1694085049
   */
  launch_timestamp: number;
  note1?: string;
  note2?: string;
  /**
   * Station Latitude
   * @example 23.5
   */
  latitude: number;
  /**
   * Station Longitude
   * @example 121.5
   */
  longitude: number;
  /**
   * Station Altitude
   * @example 72
   */
  altitude: number;
  /**
   * StormTacker Pressure (hPa)
   * @example 1010.12
   */
  st_pres?: number;
  /**
   * StormTacker Temperature (oC)
   * @example 25.12
   */
  st_temp?: number;
  /**
   * StormTacker Relative Humidity (%)
   * @example 50.01
   */
  st_rh?: number;
  /**
   * Reference Pressure (hPa)
   * @example 1010.12
   */
  ref_pres?: number;
  /**
   * Reference Temperature (oC)
   * @example 25.12
   */
  ref_temp?: number;
  /**
   * Reference Relative Humidity (%)
   * @example 50.01
   */
  ref_rh?: number;
  /**
   * Observation Pressure (hPa)
   * @example 1010.12
   */
  obs_pres?: number;
  /**
   * Observation Temperature (oC)
   * @example 25.12
   */
  obs_temp?: number;
  /**
   * Observation Relative Humidity (%)
   * @example 50.01
   */
  obs_rh?: number;
  /**
   * Observation Wind Speed (m/s)
   * @example 5.12
   */
  obs_ws?: number;
  /**
   * Observation Wind Direction (o)
   * @example 320
   */
  obs_wd?: number;
  /**
   * Observation Cloud Cover
   * @example "10"
   */
  obs_cloud?: string;
  /**
   * Observation Weather
   * @example "晴"
   */
  obs_weather?: string;
  /**
   * Observer name
   * @example 1
   */
  observerId: number;
}

export interface UpdateLaunchMetaRequestDto {
  /**
   * Launch Timestamp
   * @example 1694085049
   */
  launch_timestamp?: number;
  note1?: string;
  note2?: string;
  /**
   * Station Latitude
   * @example 23.5
   */
  latitude?: number;
  /**
   * Station Longitude
   * @example 121.5
   */
  longitude?: number;
  /**
   * Station Altitude
   * @example 72
   */
  altitude?: number;
  /**
   * StormTacker Pressure (hPa)
   * @example 1010.12
   */
  st_pres?: number;
  /**
   * StormTacker Temperature (oC)
   * @example 25.12
   */
  st_temp?: number;
  /**
   * StormTacker Relative Humidity (%)
   * @example 50.01
   */
  st_rh?: number;
  /**
   * Reference Pressure (hPa)
   * @example 1010.12
   */
  ref_pres?: number;
  /**
   * Reference Temperature (oC)
   * @example 25.12
   */
  ref_temp?: number;
  /**
   * Reference Relative Humidity (%)
   * @example 50.01
   */
  ref_rh?: number;
  /**
   * Observation Pressure (hPa)
   * @example 1010.12
   */
  obs_pres?: number;
  /**
   * Observation Temperature (oC)
   * @example 25.12
   */
  obs_temp?: number;
  /**
   * Observation Relative Humidity (%)
   * @example 50.01
   */
  obs_rh?: number;
  /**
   * Observation Wind Speed (m/s)
   * @example 5.12
   */
  obs_ws?: number;
  /**
   * Observation Wind Direction (o)
   * @example 320
   */
  obs_wd?: number;
  /**
   * Observation Cloud Cover
   * @example "10"
   */
  obs_cloud?: string;
  /**
   * Observation Weather
   * @example "晴"
   */
  obs_weather?: string;
  /**
   * Observer name
   * @example 1
   */
  observerId?: number;
}

export interface UploadAttachmentFilesRequestDto {
  files: File[];
}

export interface AiqcRecordResponse {
  id: number;
  stId: number;
  status: AiqcRecordResponseStatusEnum;
  message: string;
}

export interface AiqcDownloadRequestBody {
  /**
   * StormTracker Ids
   * @example [3301,3302,3303]
   */
  stids: number[];
}

export interface AiqcDownloadEntityDto {
  /** @format uuid */
  uuid: string;
  status: AiqcDownloadEntityDtoStatusEnum;
  request: AiqcDownloadRequestBody;
  /** @default 1699600235410 */
  exp: number;
}

/** 身分別 */
export enum SignUpRequestDtoRoleEnum {
  Admin = "admin",
  Pm = "pm",
  Pa = "pa",
  Station = "station",
  Anonymous = "anonymous",
}

/** @default "anonymous" */
export enum UserProfileResponseDtoRoleEnum {
  Admin = "admin",
  Pm = "pm",
  Pa = "pa",
  Station = "station",
  Anonymous = "anonymous",
}

/** @example "preiop" */
export enum ProjectResponseDtoStatusEnum {
  Preiop = "preiop",
  Iop = "iop",
  Postiop = "postiop",
  Archived = "archived",
}

export enum ProjectStatusDtoStatusEnum {
  Preiop = "preiop",
  Iop = "iop",
  Postiop = "postiop",
  Archived = "archived",
}

/** @example "preiop" */
export enum ProjectResponseWithoutStationDtoStatusEnum {
  Preiop = "preiop",
  Iop = "iop",
  Postiop = "postiop",
  Archived = "archived",
}

export enum AiqcRecordResponseStatusEnum {
  Nodata = "nodata",
  Pending = "pending",
  Processing = "processing",
  Complete = "complete",
  Error = "error",
}

export enum AiqcDownloadEntityDtoStatusEnum {
  Pending = "pending",
  Processing = "processing",
  Complete = "complete",
  Error = "error",
}

/** Attachment Type */
export enum AttachmentControllerUploadLaunchAttachementsParamsTypeEnum {
  Image = "image",
  Edt = "edt",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title AIQC API
 * @version 1.2.12
 * @contact
 *
 * AIQC API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @name PrometheusControllerIndex
     * @request GET:/api/metrics
     */
    prometheusControllerIndex: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/metrics`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentications
     * @name AuthControllerSingIn
     * @summary 使用帳號密碼登入並取得 access_token
     * @request POST:/api/auth/login
     */
    authControllerSingIn: (data: SignInRequestDto, params: RequestParams = {}) =>
      this.request<SignInResponseDto, any>({
        path: `/api/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentications
     * @name AuthControllerSingUp
     * @request POST:/api/auth/signup
     */
    authControllerSingUp: (data: SignUpRequestDto, params: RequestParams = {}) =>
      this.request<BasicResponseDto, void>({
        path: `/api/auth/signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerGetProfile
     * @request GET:/api/users/profile
     * @secure
     */
    usersControllerGetProfile: (params: RequestParams = {}) =>
      this.request<UserProfileResponseDto, any>({
        path: `/api/users/profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerUpdatePassword
     * @request PUT:/api/users/password
     * @secure
     */
    usersControllerUpdatePassword: (data: UserPasswordUpdateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/password`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerResetPassword
     * @request POST:/api/users/password/reset
     * @secure
     */
    usersControllerResetPassword: (data: UserResetDto, params: RequestParams = {}) =>
      this.request<BasicResponseDto, any>({
        path: `/api/users/password/reset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectControllerGetAllProjects
     * @summary 取得所有專案
     * @request GET:/api/projects
     * @secure
     */
    projectControllerGetAllProjects: (params: RequestParams = {}) =>
      this.request<ProjectResponseDto[], any>({
        path: `/api/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectControllerCreateProject
     * @summary 新增專案
     * @request POST:/api/projects
     * @secure
     */
    projectControllerCreateProject: (data: ProjectRequestDto, params: RequestParams = {}) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectControllerGetProject
     * @summary 取得單一專案
     * @request GET:/api/projects/{projectId}
     * @secure
     */
    projectControllerGetProject: (projectId: number, params: RequestParams = {}) =>
      this.request<ProjectResponseDto, any>({
        path: `/api/projects/${projectId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectControllerSetProjectStatus
     * @summary 修改專案狀態
     * @request PUT:/api/projects/{projectId}
     * @secure
     */
    projectControllerSetProjectStatus: (projectId: number, data: ProjectStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectControllerDeleteProject
     * @summary 刪除單一專案
     * @request DELETE:/api/projects/{projectId}
     * @secure
     */
    projectControllerDeleteProject: (projectId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectControllerGetProjectToken
     * @summary 取得專案 token
     * @request GET:/api/projects/{projectId}/token
     * @secure
     */
    projectControllerGetProjectToken: (projectId: number, params: RequestParams = {}) =>
      this.request<ProjectTokenResponseDto, any>({
        path: `/api/projects/${projectId}/token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Station
     * @name StationControllerGetStations
     * @summary 取得所有測站
     * @request GET:/api/projects/{projectId}/stations
     * @secure
     */
    stationControllerGetStations: (projectId: number, params: RequestParams = {}) =>
      this.request<StationWithoutRelationDto[], any>({
        path: `/api/projects/${projectId}/stations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Station
     * @name StationControllerCreateStation
     * @summary 新增測站
     * @request POST:/api/projects/{projectId}/stations
     * @secure
     */
    stationControllerCreateStation: (projectId: number, data: StationRequestDto, params: RequestParams = {}) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Station
     * @name StationControllerGetStation
     * @summary 取得測站詳細資訊
     * @request GET:/api/projects/{projectId}/stations/{stationId}
     * @secure
     */
    stationControllerGetStation: (projectId: number, stationId: number, params: RequestParams = {}) =>
      this.request<StationEntityDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Station
     * @name StationControllerUpdateStation
     * @summary 修改測站資訊
     * @request PUT:/api/projects/{projectId}/stations/{stationId}
     * @secure
     */
    stationControllerUpdateStation: (
      projectId: number,
      stationId: number,
      data: StationRequestPartialDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/stations/${stationId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Station
     * @name StationControllerDeleteStation
     * @summary 刪除測站（同步將底下的觀測員刪除）
     * @request DELETE:/api/projects/{projectId}/stations/{stationId}
     * @secure
     */
    stationControllerDeleteStation: (projectId: number, stationId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/stations/${stationId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Station
     * @name StationControllerGetStationToken
     * @summary 取得測站 token
     * @request GET:/api/projects/{projectId}/stations/{stationId}/token
     * @secure
     */
    stationControllerGetStationToken: (projectId: number, stationId: number, params: RequestParams = {}) =>
      this.request<StationTokenResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LoRaFile
     * @name LoraControllerGetLoraFileList
     * @summary 取得所有上傳的 LoRa 檔案，並依照上傳時間由新到舊
     * @request GET:/api/projects/{projectId}/stations/{stationId}/loras
     * @secure
     */
    loraControllerGetLoraFileList: (projectId: number, stationId: number, params: RequestParams = {}) =>
      this.request<GetLoRaResponseDto[], any>({
        path: `/api/projects/${projectId}/stations/${stationId}/loras`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LoRaFile
     * @name LoraControllerUploadLoraFile
     * @summary 上傳 LoRa 檔案
     * @request POST:/api/projects/{projectId}/stations/{stationId}/loras
     * @secure
     */
    loraControllerUploadLoraFile: (
      projectId: number,
      stationId: number,
      data: UploadLoraFileRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/loras`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LoRaFile
     * @name LoraControllerMarkLoraFile
     * @summary 將該 LoRa 檔案(id)標記為不納入/納入合併
     * @request PUT:/api/projects/{projectId}/stations/{stationId}/loras/{loraId}
     * @secure
     */
    loraControllerMarkLoraFile: (
      projectId: number,
      stationId: number,
      loraId: number,
      data: LoraFilePutDto,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/loras/${loraId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LoRaFile
     * @name LoraControllerDeleteLoraFile
     * @summary 將該 LoRa 檔案(id)刪除（無法還原）
     * @request DELETE:/api/projects/{projectId}/stations/{stationId}/loras/{loraId}
     * @secure
     */
    loraControllerDeleteLoraFile: (projectId: number, stationId: number, loraId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/loras/${loraId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Observer
     * @name ObserverControllerCreateObserver
     * @summary 新增觀測員
     * @request POST:/api/projects/{projectId}/stations/{stationId}/observers
     * @secure
     */
    observerControllerCreateObserver: (
      projectId: number,
      stationId: number,
      data: ObserverCreateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/observers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Observer
     * @name ObserverControllerDeleteObserver
     * @summary 刪除觀測員
     * @request DELETE:/api/projects/{projectId}/stations/{stationId}/observers/{observerId}
     * @secure
     */
    observerControllerDeleteObserver: (
      observerId: number,
      projectId: number,
      stationId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/observers/${observerId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Launch
     * @name LaunchControllerGetLaunchs
     * @request GET:/api/projects/{projectId}/stations/{stationId}/launches
     * @secure
     */
    launchControllerGetLaunchs: (projectId: number, stationId: number, params: RequestParams = {}) =>
      this.request<LaunchMetaWithAttDto[], any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Launch
     * @name LaunchControllerAddLaunchRecord
     * @request POST:/api/projects/{projectId}/stations/{stationId}/launches
     * @secure
     */
    launchControllerAddLaunchRecord: (
      projectId: number,
      stationId: number,
      data: UploadLaunchMetaRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Launch
     * @name LaunchControllerGetLaunch
     * @request GET:/api/projects/{projectId}/stations/{stationId}/launches/{launchId}
     * @secure
     */
    launchControllerGetLaunch: (launchId: number, projectId: number, stationId: number, params: RequestParams = {}) =>
      this.request<LaunchMetaDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches/${launchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Launch
     * @name LaunchControllerUpdateLaunchRecord
     * @request PUT:/api/projects/{projectId}/stations/{stationId}/launches/{launchId}
     * @secure
     */
    launchControllerUpdateLaunchRecord: (
      projectId: number,
      stationId: number,
      launchId: number,
      data: UpdateLaunchMetaRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches/${launchId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachment
     * @name AttachmentControllerUploadLaunchAttachements
     * @request POST:/api/projects/{projectId}/stations/{stationId}/launches/{launchId}/attachments
     * @secure
     */
    attachmentControllerUploadLaunchAttachements: (
      projectId: number,
      stationId: number,
      launchId: number,
      query: {
        /** Attachment Type */
        type: AttachmentControllerUploadLaunchAttachementsParamsTypeEnum;
      },
      data: UploadAttachmentFilesRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches/${launchId}/attachments`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachment
     * @name AttachmentControllerDownloadLaunchAttachements
     * @request GET:/api/projects/{projectId}/stations/{stationId}/launches/{launchId}/attachments/{attachmentId}
     * @secure
     */
    attachmentControllerDownloadLaunchAttachements: (
      projectId: number,
      stationId: number,
      launchId: number,
      attachmentId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches/${launchId}/attachments/${attachmentId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachment
     * @name AttachmentControllerDeleteLaunchAttachements
     * @request DELETE:/api/projects/{projectId}/stations/{stationId}/launches/{launchId}/attachments/{attachmentId}
     * @secure
     */
    attachmentControllerDeleteLaunchAttachements: (
      projectId: number,
      stationId: number,
      launchId: number,
      attachmentId: number,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/launches/${launchId}/attachments/${attachmentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIQC
     * @name AiqcControllerGetAiqcRecord
     * @request GET:/api/projects/{projectId}/stations/{stationId}/aiqc
     * @secure
     */
    aiqcControllerGetAiqcRecord: (
      projectId: number,
      stationId: number,
      query: {
        all: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AiqcRecordResponse[], any>({
        path: `/api/projects/${projectId}/stations/${stationId}/aiqc`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIQC
     * @name AiqcControllerPostDownloadRequest
     * @request POST:/api/projects/{projectId}/stations/{stationId}/aiqc/download
     * @secure
     */
    aiqcControllerPostDownloadRequest: (
      projectId: number,
      stationId: number,
      data: AiqcDownloadRequestBody,
      params: RequestParams = {},
    ) =>
      this.request<BasicResponseDto, any>({
        path: `/api/projects/${projectId}/stations/${stationId}/aiqc/download`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIQC
     * @name AiqcDownloadControllerGetDownloads
     * @request GET:/api/aiqc/downloads
     * @secure
     */
    aiqcDownloadControllerGetDownloads: (params: RequestParams = {}) =>
      this.request<AiqcDownloadEntityDto[], any>({
        path: `/api/aiqc/downloads`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIQC
     * @name AiqcDownloadControllerDownloadFile
     * @request GET:/api/aiqc/downloads/{uuid}
     * @secure
     */
    aiqcDownloadControllerDownloadFile: (uuid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/aiqc/downloads/${uuid}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
}
