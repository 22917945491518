
import { defineComponent } from "vue";
import { computed, ref } from "vue";
import { UploadLaunchMetaRequestDto } from "@/api/api";
import { apiClient } from "@/scrpts/service";
import Clock from "@/components/ClockDisplay.vue";
import moment from "moment";
import Cookies from "js-cookie";

export default defineComponent({
  name: "HelloWorld",
  components: {
    Clock,
  },
  data() {
    return {
      pid: 0,
      sid: 0,
      pic_inputFile: ref<File[] | undefined>(undefined),
      edt_inputFile: ref<File[] | undefined>(undefined),
      auth_token: "",
      pic_checked: false,
      coLaunch_checked: false,
      text_submit: "",
      observers: ["戴某某", "王某某", "陳某某"],
      st_data: {
        launch_time: "",
        st_id: "",
        observer: "",
        project_name: "",
        stn_name: "",
        lat: "",
        lon: "",
        alt: "",
        st_p: "",
        ref_p: "",
        st_t: "",
        ref_t: "",
        st_rh: "",
        ref_rh: "",
        grd_p: "",
        grd_t: "",
        grd_rh: "",
        grd_ws: "",
        grd_wd: "",
        cloud: "",
        weather: "",
        note1: "",
        note2: "",
      } as { [key: string]: string },
      output_data: {
        project: "TEST_2023",
        stId: -99,
        launch_timestamp: -99,
        note1: "",
        note2: "",
        station: "",
        observerId: "",
        latitude: -99.9,
        longitude: -99.9,
        altitude: -99.9,
        st_pres: -99.9,
        st_temp: -99.9,
        st_rh: -99.9,
        ref_pres: -99.9,
        ref_temp: -99.9,
        ref_rh: -99.9,
        obs_pres: -99.9,
        obs_temp: -99.9,
        obs_rh: -99.9,
        obs_ws: -99.9,
        obs_wd: -99.9,
        obs_cloud: "",
        obs_weather: "",
        attachments: [],
      } as { [key: string]: any },
      int_group: {
        st_id: "stId",
        observer: "observerId",
      } as { [key: string]: string },
      float_group: {
        lat: "latitude",
        lon: "longitude",
        alt: "altitude",
        st_p: "st_pres",
        ref_p: "ref_pres",
        st_t: "st_temp",
        ref_t: "ref_temp",
        st_rh: "st_rh",
        ref_rh: "ref_rh",
        grd_p: "obs_pres",
        grd_t: "obs_temp",
        grd_rh: "obs_rh",
        grd_ws: "obs_ws",
        grd_wd: "obs_wd",
      } as { [key: string]: string },
      str_group: {
        stn_name: "station",
        project_name: "project",
        cloud: "obs_cloud",
        weather: "obs_weather",
        note1: "note1",
        note2: "note2",
      } as { [key: string]: string },
      check_list: ["st_id"],
    };
  },
  methods: {
    fileChange(event: any) {
      const htmlId = event.target.id;
      const files = event.target.files as { [name: string]: File };
      const filelist = [];

      switch (htmlId) {
        case "pic_file":
          this.pic_inputFile = Object.values(files).map((file) => file);
          break;
        case "colaunch_file":
          this.edt_inputFile = Object.values(files);
          break;
      }
    },
    get_nowTime() {
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss")
      );
      this.st_data.launch_time = dateTimeStr.value;
    },
    submit() {
      const st_timestamp = new Date(this.st_data.launch_time).getTime() / 1000;

      for (var i in this.check_list) {
        const item: string = this.check_list[i];
        if (this.st_data[item] == "") {
          alert("請檢查欄位是否填齊!");
          return;
        }
      }

      this.output_data.launch_timestamp = parseInt(String(st_timestamp));

      const keys_int = Object.keys(this.int_group);
      keys_int.forEach((key) => {
        if (String(this.st_data[key]) != "") {
          this.output_data[this.int_group[key]] = parseInt(this.st_data[key]);
        }
      });

      const keys_float = Object.keys(this.float_group);
      keys_float.forEach((key) => {
        if (String(this.st_data[key]) != "") {
          this.output_data[this.float_group[key]] = parseFloat(
            this.st_data[key]
          );
        }
      });

      const keys_str = Object.keys(this.str_group);
      keys_str.forEach((key) => {
        if (String(this.st_data[key]) != "") {
          this.output_data[this.str_group[key]] = this.st_data[key];
        }
      });

      const output_data: UploadLaunchMetaRequestDto = {
        stId: this.output_data.stId,
        launch_timestamp: this.output_data.launch_timestamp,
        note1: this.output_data.note1,
        note2: this.output_data.note2,
        observerId: this.output_data.observerId,
        latitude: this.output_data.latitude,
        longitude: this.output_data.longitude,
        altitude: this.output_data.altitude,
        st_pres: this.output_data.st_pres,
        st_temp: this.output_data.st_temp,
        st_rh: this.output_data.st_rh,
        ref_pres: this.output_data.ref_pres,
        ref_temp: this.output_data.ref_temp,
        ref_rh: this.output_data.ref_rh,
        obs_pres: this.output_data.obs_pres,
        obs_temp: this.output_data.obs_temp,
        obs_rh: this.output_data.obs_rh,
        obs_ws: this.output_data.obs_ws,
        obs_wd: this.output_data.obs_wd,
        obs_cloud: this.output_data.obs_cloud,
        obs_weather: this.output_data.obs_weather,
      };

      apiClient.api
        .launchControllerAddLaunchRecord(this.pid, this.sid, output_data)
        .then((response) => {
          alert(this.$t("AddMission.success"));
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          console.log(error);
          alert(this.$t("AddMission.failed"));
        });
    },
  },
  async mounted() {
    this.text_submit = this.$t("AddMission.submit");
    const pid = Cookies.get("pid");
    const sid = Cookies.get("sid");

    this.pid = pid !== undefined ? parseInt(pid) : 0;
    this.sid = sid !== undefined ? parseInt(sid) : 0;

    apiClient.api
      .stationControllerGetStation(this.pid, this.sid)
      .then((response) => {
        const data = response.data as { [key: string]: any };
        this.observers = data.observers;
        this.st_data.project_name = data.project.name;
        this.st_data.lat = data.latitude;
        this.st_data.lon = data.longitude;
        this.st_data.alt = data.altitude;
        this.st_data.stn_name = data.name;
      });
  },
});
