
import { computed, ref } from "vue";
import moment from "moment";

export default {
  setup() {
    const datetime = ref(moment());
    const now = () => {
      datetime.value = moment();
    };
    const dateStr = computed(() => datetime.value.format("YYYY/MM/DD"));
    const timeStr = computed(() => datetime.value.format("HH:mm:ss"));

    setInterval(now, 1000);

    return {
      dateStr,
      timeStr,
    };
  },
};
