import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  class: "w3-content w3-padding",
  style: {"max-width":"1200px"}
}
const _hoisted_2 = {
  style: {"font-family":"Microsoft JhengHei"},
  class: "w3-border-bottom w3-border-light-grey w3-padding-16"
}
const _hoisted_3 = { style: {"color":"rgb(255, 0, 0)"} }
const _hoisted_4 = { style: {"color":"rgb(255, 0, 0)"} }
const _hoisted_5 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_6 = { class: "field_name" }
const _hoisted_7 = { value: "" }
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_11 = { class: "field_name" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_14 = { class: "field_name" }
const _hoisted_15 = { value: "" }
const _hoisted_16 = ["value"]
const _hoisted_17 = ["value"]
const _hoisted_18 = { style: {"float":"left","width":"50%","padding":"5px"} }
const _hoisted_19 = { class: "field_name" }
const _hoisted_20 = ["value"]
const _hoisted_21 = {
  class: "w3-border-bottom w3-border-light-grey w3-padding-16",
  style: {"font-family":"Microsoft JhengHei","margin-top":"200px"}
}
const _hoisted_22 = ["value"]
const _hoisted_23 = {
  class: "w3-border-bottom w3-border-light-grey w3-padding-16",
  style: {"font-family":"Microsoft JhengHei","margin-top":"100px"}
}
const _hoisted_24 = { class: "table" }
const _hoisted_25 = { scope: "col" }
const _hoisted_26 = { scope: "col" }
const _hoisted_27 = { scope: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_copy_text = _resolveDirective("copy-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("Projectcontrol.title")), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("Projectcontrol.caution1")), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("Projectcontrol.caution2")) + " !\" # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ ` { | } ~) ", 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("Projectcontrol.project")) + "*：", 1),
      _withDirectives(_createElementVNode("select", {
        input: "",
        class: "input_form",
        style: {"float":"left","width":"70%"},
        name: "observer",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.project_change && _ctx.project_change(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sel_project_id) = $event))
      }, [
        _createElementVNode("option", _hoisted_7, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: project.id
          }, _toDisplayString(project.name), 9, _hoisted_8))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.sel_project_id]
      ]),
      _createElementVNode("input", {
        style: {"float":"left","right":"0px","height":"45px","width":"28%","font-size":"20px","margin-top":"-20px","margin-left":"1%","margin-right":"1%"},
        type: "submit",
        name: "send",
        value: _ctx.text_delete,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.delete_project()))
      }, null, 8, _hoisted_9)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("Projectcontrol.add_project")) + "：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        style: {"float":"left","width":"70%"},
        type: "text",
        name: "project_name",
        id: "project_name",
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.new_project) = $event))
      }, null, 544), [
        [_vModelText, _ctx.new_project]
      ]),
      _createElementVNode("input", {
        style: {"float":"left","right":"0px","height":"45px","width":"28%","margin-top":"-20px","font-size":"20px","margin-left":"1%","margin-right":"1%"},
        type: "submit",
        name: "send",
        value: _ctx.text_add,
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.add_project()))
      }, null, 8, _hoisted_12)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("Projectcontrol.station")) + "*：", 1),
      _withDirectives(_createElementVNode("select", {
        input: "",
        class: "input_form",
        style: {"float":"left","width":"70%"},
        name: "observer",
        onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.station_change && _ctx.station_change(...args))),
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sel_station_id) = $event))
      }, [
        _createElementVNode("option", _hoisted_15, "--" + _toDisplayString(_ctx.$t("StationInf.list")) + "--", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stations, (station, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: station.id
          }, _toDisplayString(station.name), 9, _hoisted_16))
        }), 128))
      ], 544), [
        [_vModelSelect, _ctx.sel_station_id]
      ]),
      _createElementVNode("input", {
        style: {"float":"left","right":"0px","height":"45px","width":"28%","font-size":"20px","margin-top":"-20px","margin-left":"1%","margin-right":"1%"},
        type: "submit",
        name: "send",
        value: _ctx.text_delete,
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.delete_station()))
      }, null, 8, _hoisted_17)
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("Projectcontrol.add_station")) + "：", 1),
      _withDirectives(_createElementVNode("input", {
        class: "input_form",
        style: {"float":"left","width":"70%"},
        type: "text",
        name: "project_name",
        id: "project_name",
        onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.new_station) = $event))
      }, null, 544), [
        [_vModelText, _ctx.new_station]
      ]),
      _createElementVNode("input", {
        style: {"float":"left","right":"0px","height":"45px","width":"28%","font-size":"20px","margin-top":"-20px","margin-left":"1%","margin-right":"1%"},
        type: "submit",
        name: "send",
        value: _ctx.text_add,
        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.add_station()))
      }, null, 8, _hoisted_20)
    ]),
    _createElementVNode("h2", _hoisted_21, _toDisplayString(_ctx.$t("Projectcontrol.status_title")), 1),
    _createElementVNode("input", {
      style: {"float":"left","right":"0px","height":"45px","width":"100%","font-size":"20px","margin-left":"1%","margin-right":"1%"},
      type: "submit",
      name: "send",
      value: _ctx.project_status,
      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.change_status()))
    }, null, 8, _hoisted_22),
    _createElementVNode("h2", _hoisted_23, _toDisplayString(_ctx.$t("Projectcontrol.for_register")) + " - " + _toDisplayString(_ctx.project_text), 1),
    _createElementVNode("table", _hoisted_24, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t("Projectcontrol.role")), 1),
          _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t("Projectcontrol.url")), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signup_token, (role, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("th", _hoisted_27, _toDisplayString(role.name), 1),
            _createElementVNode("td", null, " https://aiqc.atmos.pccu.edu.tw/register?role=" + _toDisplayString(role.role) + "&token=" + _toDisplayString(role.token), 1),
            _createElementVNode("td", null, [
              _withDirectives((_openBlock(), _createElementBlock("button", null, [
                _createTextVNode(_toDisplayString(_ctx.$t("Projectcontrol.copy")), 1)
              ])), [
                [_directive_copy_text, () => _ctx.roleUrl(role)]
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}