
import { defineComponent, ref } from "vue";
import Cookies from "js-cookie";
import { apiClient } from "@/scrpts/service";
import { timestampTransformer, fetch_token } from "@/scrpts/utils";

export default defineComponent({
  name: "NavBar",
  data() {
    return {
      auth_token: "",
      super_user: ref(false),
      exp_time: "",
      isLogged: this.checkIfIsLogged(),
      now_locale: "" as string | undefined,
      logout_locale: "CHT" as string | undefined,
    };
  },
  components: {},
  methods: {
    change_locale() {
      console.log(this.now_locale);
      Cookies.set("locale", String(this.now_locale));
      this.$router.go(0);
    },
    change_locale_logout() {
      console.log(this.now_locale);
      Cookies.set("locale", String(this.now_locale));
      this.$router.go(0);
    },
    checkIfIsLogged() {
      const info = Cookies.get("login");
      if (info) {
        const token = JSON.parse(info).token;
        if (token.length > 0 || token === undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    parseJwt(auth_token: string) {
      var base64Url = auth_token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
  async mounted() {
    try {
      const info = Cookies.get("login");
      var token = "";
      if (info) {
        token = JSON.parse(info).token;
      }

      const user_data = this.parseJwt(token);
      const exp_time_text = timestampTransformer(
        parseInt(user_data.exp) * 1000
      );

      this.exp_time = exp_time_text;

      await apiClient.api
        .usersControllerGetProfile()
        .then((response) => {
          if (
            response.data.role == "admin" ||
            response.data.role == "pm" ||
            response.data.role == "pa"
          ) {
            this.super_user = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      if (Cookies.get("locale")) {
        console.log("now locale:" + Cookies.get("locale"));
        this.now_locale = Cookies.get("locale");
        this.logout_locale = Cookies.get("locale");
      } else {
        Cookies.set("locale", "zh-TW");
        this.now_locale = Cookies.get("locale");
        this.logout_locale = Cookies.get("locale");
      }
    } catch {
      console.log("not logged in");
    }
  },
});
