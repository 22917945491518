
import { defineComponent } from "vue";
import { apiClient } from "@/scrpts/service";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";

export default defineComponent({
  name: "HelloWorld",
  components: {},
  data() {
    return {
      pid: 0,
      sid: 0,
      text_chagePass: "",
      old_password: "",
      new_password: "",
      new_password_chk: "",
      token: "",
    };
  },
  methods: {
    logout() {
      Cookies.remove("login");
      Cookies.remove("locale");
      Cookies.remove("pid");
      Cookies.remove("sid");
      this.$router.push({ name: "login" });
    },
    submit() {
      if (this.old_password != "") {
        if (this.new_password == this.new_password_chk) {
          const body = {
            originalPassword: this.old_password,
            newPassword: this.new_password,
          };

          apiClient.api
            .usersControllerUpdatePassword(body)
            .then((response) => {
              alert(this.$t("ChangePass.success"));
              this.logout();
            })
            .catch((error) => {
              alert(this.$t("ChangePass.wrong_password"));
            });
        } else {
          alert(this.$t("ChangePass.password_not_match"));
        }
      } else {
        alert(this.$t("ChangePass.user_name_empty"));
      }
    },
    mounted() {
      this.text_chagePass = this.$t("ChangePass.title");
      const pid = Cookies.get("pid");
      const sid = Cookies.get("sid");

      this.pid = pid !== undefined ? parseInt(pid) : 99999;
      this.sid = sid !== undefined ? parseInt(sid) : 99999;
    },
  },
});
