
import { defineComponent, ref } from "vue";
import { computed } from "vue";
import moment from "moment";
import { apiClient } from "@/scrpts/service";
import { Api, ProjectResponseDtoStatusEnum } from "@/api/api";
import { timestampTransformer, fetch_token } from "@/scrpts/utils";
import Cookies from "js-cookie";

export default defineComponent({
  methods: {
    async page_refresh() {
      // 取得ST
      const response3 = await apiClient.api.launchControllerGetLaunchs(
        this.pid,
        this.sid
      );
      const data = response3.data as { [key: string]: any };
      data.sort(
        (a: { launch_timestamp: number }, b: { launch_timestamp: number }) =>
          b.launch_timestamp - a.launch_timestamp
      );
      this.st = [];
      for (var i in data) {
        this.pushToST(data, i);
      }
    },
    async project_change() {
      const response = await apiClient.api.stationControllerGetStations(
        this.pid
      );
      this.stations = [];
      for (var i in response.data) {
        const item = {
          id: response.data[i].id,
          name: response.data[i].name,
        };
        this.stations.push(item);
      }
      try {
        this.sid = this.stations[0].id;
        this.page_refresh();
        Cookies.set("pid", String(this.pid));
        Cookies.set("sid", String(this.stations[0].id));
      } catch {
        alert("No Data!");
      }
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
      );
      this.update_time = dateTimeStr.value;
    },
    async station_change() {
      this.page_refresh();
      Cookies.set("pid", String(this.pid));
      Cookies.set("sid", String(this.sid));
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
      );
      this.update_time = dateTimeStr.value;
    },
    pushToST(data: any, i: any): void {
      const data_time_text = timestampTransformer(
        data[i].launch_timestamp * 1000
      );

      var EDT = false;
      var PIC = false;

      for (var j in data[i].attachments) {
        //console.log(data[i].attachments[j]);
        if (data[i].attachments[j].type == "image") {
          PIC = true;
        } else if (data[i].attachments[j].type == "edt") {
          EDT = true;
        }
      }

      const newItem = {
        ID: data[i].id,
        STID: data[i].stId,
        TIME: data_time_text,
        PIC: PIC,
        EDT: EDT,
        NOTE: String(data[i].note1),
      };
      this.st.push(newItem);
    },
    edit(e: MouseEvent) {
      const target = e.target as HTMLInputElement; // 使用型別斷言
      const target_id = target.value;

      this.$router.push({
        name: "launch_edit",
        query: {
          pid: this.pid,
          sid: this.sid,
          selected_st: target_id,
        },
      });
    },
    add_mission() {
      this.$router.push({
        name: "launch",
      });
    },
  },
  data() {
    return {
      pid: 0,
      sid: 0,
      auth_token: "",
      projects: [] as {
        id: number;
        name: string;
      }[],
      stations: [] as {
        id: number;
        name: string;
      }[],
      st: [] as {
        ID: number;
        STID: string;
        TIME: string;
        PIC: boolean;
        EDT: boolean;
        NOTE: string;
      }[],
      update_time: "",
    };
  },
  async mounted() {
    const datetime = ref(moment());
    const dateTimeStr = computed(() =>
      datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
    );
    this.update_time = dateTimeStr.value;

    const info = Cookies.get("login");
    var token = "";
    if (info) {
      token = JSON.parse(info).token;
    }
    this.auth_token = token;

    try {
      // 首次登入處理
      if (!Cookies.get("pid")) {
        console.log("set cookies");
        const client = new Api();
        const params = {
          headers: {
            Authorization: `Bearer ${this.auth_token}`,
          },
        };
        // 取得 Project ID
        const response1 = await client.api.projectControllerGetAllProjects(
          params
        );

        this.projects = response1.data;
        this.pid = this.projects[0].id;
        Cookies.set("pid", String(this.projects[0].id));

        // 取得 Station ID
        const response2 = await client.api.stationControllerGetStations(
          this.projects[0].id,
          params
        );

        this.stations = response2.data;

        this.sid = this.stations[0].id;
        Cookies.set("sid", String(this.stations[0].id));
        Cookies.set("show_all", "0");

        // 首次登入 強制刷新
        this.$router.go(0);
      }

      const pid = Cookies.get("pid");
      const sid = Cookies.get("sid");

      this.pid = pid !== undefined ? parseInt(pid) : 99999;
      this.sid = sid !== undefined ? parseInt(sid) : 99999;

      this.projects = await apiClient.getProjects();
      try {
        this.stations = await apiClient.getStations(this.pid);
      } catch {
        this.stations = await apiClient.getStations(this.projects[0].id);
      }

      // 取得ST
      const response3 = await apiClient.api.launchControllerGetLaunchs(
        this.pid,
        this.sid
      );
      const data = response3.data as { [key: string]: any };
      data.sort(
        (a: { launch_timestamp: number }, b: { launch_timestamp: number }) =>
          b.launch_timestamp - a.launch_timestamp
      );
      for (var i in data) {
        this.pushToST(data, i);
      }
    } catch (error) {
      console.log(error);
    }
  },
});
