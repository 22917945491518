
import { defineComponent } from "vue";
import { Api, SignUpRequestDto, SignUpRequestDtoRoleEnum } from "@/api/api";
import Cookies from "js-cookie";

export default defineComponent({
  name: "HelloWorld",
  components: {},
  data() {
    return {
      user: "",
      pass: "",
      pass_chk: "",
      token: "",
      role: "",
      role_text: "",
      station: "",
    };
  },
  methods: {
    submit() {
      const role_ = String(this.$route.query.role);
      const token = String(this.$route.query.token);
      const station = String(this.$route.query.station);

      this.role = role_;
      this.token = token;
      this.station = station;

      console.log("");

      if (this.user != "") {
        if (this.pass == this.pass_chk) {
          const client = new Api();
          var role;

          switch (this.role) {
            case SignUpRequestDtoRoleEnum.Station:
              role = SignUpRequestDtoRoleEnum.Station;
              break;
            case SignUpRequestDtoRoleEnum.Pa:
              role = SignUpRequestDtoRoleEnum.Pa;
              break;
            case SignUpRequestDtoRoleEnum.Pm:
              role = SignUpRequestDtoRoleEnum.Pm;
              break;
            case SignUpRequestDtoRoleEnum.Anonymous:
              role = SignUpRequestDtoRoleEnum.Anonymous;
              break;
            case SignUpRequestDtoRoleEnum.Admin:
              role = SignUpRequestDtoRoleEnum.Admin;
              break;
          }

          if (role) {
            const signup_req: SignUpRequestDto = {
              username: this.user,
              password: this.pass,
              token: this.token,
              role: role,
            };
            client.api
              .authControllerSingUp(signup_req)
              .then((response) => {
                alert(this.$t("Register.success"));
                this.$router.push({ name: "login" });
              })
              .catch((error) => {
                console.log(error);
                alert(this.$t("Register.faild"));
              });
          }
        } else {
          alert(this.$t("Register.password_not_match"));
        }
      } else {
        alert(this.$t("Register.user_name_empty"));
      }
    },
  },
  mounted() {
    const role_ = String(this.$route.query.role);
    const token = String(this.$route.query.token);
    const station = String(this.$route.query.station);

    this.role_text = role_ + "-" + station;
    if (this.$route.query.station == undefined) {
      this.role_text = role_;
    }

    this.token = token;
    this.station = station;
  },
});
