import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "st_list" }
const _hoisted_2 = {
  class: "w3-content w3-padding",
  style: {"max-width":"1200px"}
}
const _hoisted_3 = { class: "w3-col l12 m12 w3-margin-bottom w3-row-padding" }
const _hoisted_4 = { class: "w3-col l12 m12 w3-margin-bottom w3-row-padding" }
const _hoisted_5 = {
  class: "w3-border-bottom w3-border-light-grey w3-padding-16",
  style: {"font-family":"Microsoft JhengHei"}
}
const _hoisted_6 = { class: "table" }
const _hoisted_7 = { scope: "col" }
const _hoisted_8 = { scope: "col" }
const _hoisted_9 = { scope: "col" }
const _hoisted_10 = ["value", "status"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("DownloadList.title")), 1),
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t("DownloadList.update_time")) + "：" + _toDisplayString(_ctx.update_time), 1),
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("DownloadList.uuid")), 1),
                _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("DownloadList.exp")), 1),
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("DownloadList.status")), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.download_list, (download) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: download.uuid
                }, [
                  _createElementVNode("td", null, _toDisplayString(download.uuid), 1),
                  _createElementVNode("td", null, _toDisplayString(download.exp), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      type: "button",
                      style: _normalizeStyle([{"height":"30px","width":"100%","margin-top":"0px","font-size":"18px","font-weight":"bold"}, {
                      color: _ctx.i18n_status.get(download.status)?.color,
                    }]),
                      value: download.uuid,
                      status: download.status,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.download_file($event)))
                    }, _toDisplayString(_ctx.i18n_status.get(download.status)?.text), 13, _hoisted_10)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}