
import { defineComponent } from "vue";
import { computed, ref } from "vue";
import { UpdateLaunchMetaRequestDto } from "@/api/api";
import { apiClient } from "@/scrpts/service";
import moment from "moment";
import fileDownload from "js-file-download";

export default defineComponent({
  name: "HelloWorld",
  components: {},
  data() {
    return {
      pid: 0,
      sid: 0,
      auth_token: "",
      selected_st: 0,
      pic_checked: false,
      coLaunch_checked: false,
      text_upload: "",
      text_submit: "",
      observers: [],
      sel_observerId: 0,
      st_data: {
        launch_time: "",
        st_id: "",
        observer: "",
        project_name: "",
        stn_name: "",
        lat: "",
        lon: "",
        alt: "",
        st_p: "",
        ref_p: "",
        st_t: "",
        ref_t: "",
        st_rh: "",
        ref_rh: "",
        grd_p: "",
        grd_t: "",
        grd_rh: "",
        grd_ws: "",
        grd_wd: "",
        cloud: "",
        weather: "",
        note1: "",
        note2: "",
      } as { [key: string]: string },
      output_data: {
        launch_timestamp: -99,
        note1: "",
        note2: "",
        observerId: "",
        latitude: -99.9,
        longitude: -99.9,
        altitude: -99.9,
        st_pres: -99.9,
        st_temp: -99.9,
        st_rh: -99.9,
        ref_pres: -99.9,
        ref_temp: -99.9,
        ref_rh: -99.9,
        obs_pres: -99.9,
        obs_temp: -99.9,
        obs_rh: -99.9,
        obs_ws: -99.9,
        obs_wd: -99.9,
        obs_cloud: "",
        obs_weather: "",
        attachments: [],
      } as { [key: string]: any },
      columns: {
        FILE_NAME: "檔名",
        TYPE: "類型",
        TIME: "上傳時間",
        DOWNLOAD: "下載",
      },
      attch: [] as {
        FILE_NAME: string;
        TYPE: string;
        TIME: string;
        FILE_ID: number;
      }[],
      int_group: {} as { [key: string]: string },
      float_group: {
        lat: "latitude",
        lon: "longitude",
        alt: "altitude",
        st_p: "st_pres",
        ref_p: "ref_pres",
        st_t: "st_temp",
        ref_t: "ref_temp",
        st_rh: "st_rh",
        ref_rh: "ref_rh",
        grd_p: "obs_pres",
        grd_t: "obs_temp",
        grd_rh: "obs_rh",
        grd_ws: "obs_ws",
        grd_wd: "obs_wd",
      } as { [key: string]: string },
      str_group: {
        cloud: "obs_cloud",
        weather: "obs_weather",
        note1: "note1",
        note2: "note2",
      } as { [key: string]: string },
    };
  },
  methods: {
    submit() {
      const st_timestamp = new Date(this.st_data.launch_time).getTime() / 1000;
      this.output_data.launch_timestamp = parseInt(String(st_timestamp));

      const keys_int = Object.keys(this.int_group);
      keys_int.forEach((key) => {
        if (String(this.st_data[key]) != "") {
          this.output_data[this.int_group[key]] = parseInt(this.st_data[key]);
        }
      });

      const keys_float = Object.keys(this.float_group);
      keys_float.forEach((key) => {
        if (String(this.st_data[key]) != "") {
          this.output_data[this.float_group[key]] = parseFloat(
            this.st_data[key]
          );
        }
      });

      const keys_str = Object.keys(this.str_group);
      keys_str.forEach((key) => {
        if (String(this.st_data[key]) != "") {
          this.output_data[this.str_group[key]] = this.st_data[key];
        }
      });

      const output_data: UpdateLaunchMetaRequestDto = {
        launch_timestamp: this.output_data.launch_timestamp,
        note1: this.output_data.note1,
        note2: this.output_data.note2,
        observerId: this.sel_observerId,
        latitude: this.output_data.latitude,
        longitude: this.output_data.longitude,
        altitude: this.output_data.altitude,
        st_pres: this.output_data.st_pres,
        st_temp: this.output_data.st_temp,
        st_rh: this.output_data.st_rh,
        ref_pres: this.output_data.ref_pres,
        ref_temp: this.output_data.ref_temp,
        ref_rh: this.output_data.ref_rh,
        obs_pres: this.output_data.obs_pres,
        obs_temp: this.output_data.obs_temp,
        obs_rh: this.output_data.obs_rh,
        obs_ws: this.output_data.obs_ws,
        obs_wd: this.output_data.obs_wd,
        obs_cloud: this.output_data.obs_cloud,
        obs_weather: this.output_data.obs_weather,
      };

      console.log(output_data);
      apiClient.api
        .launchControllerUpdateLaunchRecord(
          this.pid,
          this.sid,
          this.selected_st,
          output_data
        )
        .then((response) => {
          alert("修改成功");
          this.$router.push({
            name: "home",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download_file(e: MouseEvent) {
      const target = e.target as HTMLInputElement; // 使用型別斷言
      const file_id = parseInt(target.value);

      const headers = {
        responseType: "stream",
      };

      apiClient.api
        .attachmentControllerDownloadLaunchAttachements(
          this.pid,
          this.sid,
          this.selected_st,
          file_id,
          { headers }
        )
        .then((response) => {
          const filename = (
            response.headers.get("content-disposition") || `None`
          )
            .split("filename=")[1]
            .replace(/"/g, "");
          response.blob().then((blob) => {
            fileDownload(blob, decodeURI(filename));
          });
        });
    },
    get_nowTime() {
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss")
      );
      this.st_data.launch_time = dateTimeStr.value;
    },
    add_attachments() {
      this.$router.push({
        name: "attch_upload",
        query: {
          selected_launch: this.selected_st,
        },
      });
    },
    pad2(n: any) {
      // always returns a string
      return (n < 10 ? "0" : "") + n;
    },
  },
  async mounted() {
    this.text_upload = this.$t("EditMission.upload");
    this.text_submit = this.$t("EditMission.submit");

    const selectedStFromRoute = this.$route.query.selected_st;
    const pid = this.$route.query.pid;
    const sid = this.$route.query.sid;
    this.selected_st =
      selectedStFromRoute !== null ? Number(selectedStFromRoute) : 0;
    this.pid = pid !== null ? Number(pid) : 0;
    this.sid = sid !== null ? Number(sid) : 0;

    apiClient.api
      .stationControllerGetStation(this.pid, this.sid)
      .then((response) => {
        const data = response.data as { [key: string]: any };
        this.observers = data.observers;
      });

    apiClient.api
      .launchControllerGetLaunch(this.selected_st, this.pid, this.sid)
      .then((response) => {
        const data = response.data as { [key: string]: any };
        const data_time = new Date(parseInt(data.launch_timestamp) * 1000);
        const data_time_text =
          data_time.getFullYear() +
          "/" +
          this.pad2(data_time.getMonth() + 1) +
          "/" +
          this.pad2(data_time.getDate()) +
          " " +
          this.pad2(data_time.getHours()) +
          ":" +
          this.pad2(data_time.getMinutes()) +
          ":" +
          this.pad2(data_time.getSeconds());

        this.st_data.launch_time = data_time_text;
        this.st_data.st_id = data.stId;
        this.sel_observerId = data.observer.id;
        this.st_data.project_name = data.project.name;
        this.st_data.stn_name = data.station.name;
        this.st_data.lat = data.latitude;
        this.st_data.lon = data.longitude;
        this.st_data.alt = data.altitude;
        this.st_data.st_p = data.st_pres;
        this.st_data.ref_p = data.ref_pres;
        this.st_data.st_t = data.st_temp;
        this.st_data.ref_t = data.ref_temp;
        this.st_data.st_rh = data.st_rh;
        this.st_data.ref_rh = data.ref_rh;
        this.st_data.grd_p = data.obs_pres;
        this.st_data.grd_t = data.obs_temp;
        this.st_data.grd_rh = data.obs_rh;
        this.st_data.grd_ws = data.obs_ws;
        this.st_data.grd_wd = data.obs_wd;
        this.st_data.cloud = data.obs_cloud;
        this.st_data.weather = data.obs_weather;
        this.st_data.note1 = data.note1;
        this.st_data.note2 = data.note2;

        for (var i in data.attachments) {
          console.log(data.attachments[i]);
          const data_time = new Date(parseInt(data.attachments[i].upload_at));
          const data_time_text =
            this.pad2(data_time.getFullYear()) +
            "/" +
            this.pad2(data_time.getMonth() + 1) +
            "/" +
            this.pad2(data_time.getDate()) +
            " " +
            this.pad2(data_time.getHours()) +
            ":" +
            this.pad2(data_time.getMinutes()) +
            ":" +
            this.pad2(data_time.getSeconds());
          const item = {
            FILE_NAME: data.attachments[i].originalname,
            FILE_ID: data.attachments[i].id,
            TYPE: data.attachments[i].type,
            TIME: data_time_text,
          };

          this.attch.push(item);
        }
      });
  },
});
