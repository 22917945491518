
import { defineComponent } from "vue";
import Entrance from "@/components/STListView.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {
    Entrance,
  },
});
