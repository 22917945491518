
import { Api, AiqcDownloadEntityDtoStatusEnum as StatusEnum } from "@/api/api";
import { apiClient } from "@/scrpts/service";
import { timestampTransformer } from "@/scrpts/utils";
import Cookies from "js-cookie";
import fileDownload from "js-file-download";
import moment from "moment";
import { defineComponent } from "vue";

type TDownloadList = {
  uuid: string;
  exp: string;
  status: StatusEnum;
}[];

export default defineComponent({
  methods: {
    fetch_token() {
      const info = Cookies.get("login");
      return info ? JSON.parse(info).token : "";
    },
    download_file(e: MouseEvent) {
      const target = e.target as HTMLInputElement; // 使用型別斷言
      const uuid = target.value;

      if (target.getAttribute("status") !== StatusEnum.Complete) return;

      const headers = {
        responseType: "stream",
      };

      apiClient.api
        .aiqcDownloadControllerDownloadFile(uuid, { headers })
        .then((response) => {
          const filename = (
            response.headers.get("content-disposition") || `${uuid}.zip`
          )
            .split("filename=")[1]
            .replace(/"/g, "");
          response.blob().then((blob) => {
            fileDownload(blob, filename);
          });
        });
    },
  },
  data() {
    return {
      auth_token: this.fetch_token(),
      columns: {
        uuid: "下載序號",
        exp: "過期時間(LST)",
        NOTE: "狀態",
      },
      download_list: [] as TDownloadList,
      update_time: moment().format("YYYY/MM/DD HH:mm:ss [LST]"),
      i18n_status: new Map<
        StatusEnum,
        {
          text: string;
          color: string;
        }
      >(),
    };
  },
  async mounted() {
    this.i18n_status.set(StatusEnum.Pending, {
      text: this.$t("DownloadList.Pending"),
      color: "#b8974b",
    });
    this.i18n_status.set(StatusEnum.Processing, {
      text: this.$t("DownloadList.Processing"),
      color: "#b8974b",
    });
    this.i18n_status.set(StatusEnum.Error, {
      text: this.$t("DownloadList.Error"),
      color: "#bf4040",
    });
    this.i18n_status.set(StatusEnum.Complete, {
      text: this.$t("DownloadList.Complete"),
      color: "#4bb885",
    });
    apiClient.api
      .aiqcDownloadControllerGetDownloads()
      .then((response) => {
        const dtos = response.data;
        // 用時間排序
        dtos.sort((a, b) => b.exp - a.exp);
        this.download_list = dtos.map((dto) => ({
          uuid: dto.uuid,
          exp: timestampTransformer(dto.exp),
          status: dto.status,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  },
});
