
import { defineComponent, ref, computed } from "vue";
import { apiClient } from "@/scrpts/service";
import Cookies from "js-cookie";
import moment from "moment";
import { timestampTransformer } from "@/scrpts/utils";

export default defineComponent({
  methods: {
    async page_refresh() {
      this.lora_list = [];
      apiClient.api
        .loraControllerGetLoraFileList(this.pid, this.sid)
        .then((response) => {
          const data = response.data as { [key: string]: any };
          // 用時間排序
          data.sort(
            (a: { upload_at: number }, b: { upload_at: number }) =>
              b.upload_at - a.upload_at
          );
          for (var i in data) {
            var highlight = "";
            const data_time_text = timestampTransformer(
              parseInt(data[i].upload_at)
            );
            if (data[i].inUse == false) {
              highlight = "background-color:#CD6A6A";
            }

            var list_data = {
              ID: String(data[i].id),
              FILE_NAME: String(data[i].originalname),
              UPLOADED_TIME: String(data_time_text),
              inuse: data[i].inUse,
              COL_STYLE: String(highlight),
            };

            this.lora_list.push(list_data);
          }
        })
        .catch((error) => {
          console.log(error);
        }); // TEST
    },
    async project_change() {
      const response = await apiClient.api.stationControllerGetStations(
        this.pid
      );
      this.stations = [];
      for (var i in response.data) {
        const item = {
          id: response.data[i].id,
          name: response.data[i].name,
        };
        this.stations.push(item);
      }
      try {
        this.sid = this.stations[0].id;
        this.page_refresh();
        Cookies.set("pid", String(this.pid));
        Cookies.set("sid", String(this.stations[0].id));
      } catch {
        alert("No Data!");
      }
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
      );
      this.update_time = dateTimeStr.value;
    },
    async station_change() {
      this.page_refresh();
      Cookies.set("pid", String(this.pid));
      Cookies.set("sid", String(this.sid));
      const datetime = ref(moment());
      const dateTimeStr = computed(() =>
        datetime.value.format("YYYY/MM/DD HH:mm:ss [LST]")
      );
      this.update_time = dateTimeStr.value;
    },
    delete_item(e: MouseEvent) {
      const target = e.target as HTMLInputElement; // 使用型別斷言
      const value = target.value;

      const data = {
        inUse: false,
        forced: false,
      };
      apiClient.api
        .loraControllerMarkLoraFile(
          this.pid,
          this.sid,
          parseInt(String(value)),
          data
        )
        .then((response) => {
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    restore_item(e: MouseEvent) {
      const target = e.target as HTMLInputElement; // 使用型別斷言
      const value = target.value;

      const data = {
        inUse: true,
        forced: false,
      };
      apiClient.api
        .loraControllerMarkLoraFile(
          this.pid,
          this.sid,
          parseInt(String(value)),
          data
        )
        .then((response) => {
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  data() {
    return {
      super_user: false,
      pid: 0,
      sid: 0,
      projects: [] as {
        id: number;
        name: string;
      }[],
      stations: [] as {
        id: number;
        name: string;
      }[],
      auth_token: "",
      columns: {
        ID: "序號",
        FILE_NAME: "檔案名稱",
        UPLOADED_TIME: "上傳時間",
        DELETE: "刪除",
      },
      lora_list: [] as {
        ID: string;
        FILE_NAME: string;
        UPLOADED_TIME: string;
        inuse: boolean;
        COL_STYLE: string;
      }[],
      update_time: "2023/08/26 17:00 LST",
    };
  },
  async mounted() {
    const datetime = ref(moment());
    const dateTimeStr = computed(() =>
      datetime.value.format("YYYY/MM/DD HH:mm:ss")
    );
    this.update_time = dateTimeStr.value + " LST";

    const pid = Cookies.get("pid");
    const sid = Cookies.get("sid");

    this.pid = pid !== undefined ? parseInt(pid) : 99999;
    this.sid = sid !== undefined ? parseInt(sid) : 99999;

    this.projects = await apiClient.getProjects();
    this.stations = await apiClient.getStations(this.pid);

    await apiClient.api
      .usersControllerGetProfile()
      .then((response) => {
        if (response.data.role == "admin") {
          this.super_user = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    apiClient.api
      .loraControllerGetLoraFileList(this.pid, this.sid)
      .then((response) => {
        const data = response.data as { [key: string]: any };
        // 用時間排序
        data.sort(
          (a: { upload_at: number }, b: { upload_at: number }) =>
            b.upload_at - a.upload_at
        );
        for (var i in data) {
          var highlight = "";
          const data_time_text = timestampTransformer(
            parseInt(data[i].upload_at)
          );
          if (data[i].inUse == false) {
            highlight = "background-color:#CD6A6A";
          }

          var list_data = {
            ID: String(data[i].id),
            FILE_NAME: String(data[i].originalname),
            UPLOADED_TIME: String(data_time_text),
            inuse: data[i].inUse,
            COL_STYLE: String(highlight),
          };

          this.lora_list.push(list_data);
        }
      })
      .catch((error) => {
        console.log(error);
      }); // TEST
  },
});
