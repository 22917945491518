
import { defineComponent, ref } from "vue";
import { apiClient } from "@/scrpts/service";
import {
  UserProfileResponseDtoRoleEnum,
  ProjectResponseDtoStatusEnum,
} from "@/api/api";
import Cookies from "js-cookie";

export default defineComponent({
  methods: {
    checkIfIsLogged() {
      const info = Cookies.get("login");
      if (info) {
        const token = JSON.parse(info).token;
        if (token.length > 0 || token === undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      pid: 99999,
      user: "",
      role: "",
      status: "",
      isLogged: this.checkIfIsLogged(),
      projects: [] as {
        id: number;
        name: string;
        status: string;
      }[],
      update_time: "",
    };
  },
  async mounted() {
    try {
      await apiClient.api.usersControllerGetProfile().then((response) => {
        this.user = response.data.username;
        switch (response.data.role) {
          case UserProfileResponseDtoRoleEnum.Admin:
            this.role = "Admin";
            break;
          case UserProfileResponseDtoRoleEnum.Pa:
            this.role = "PA";
            break;
          case UserProfileResponseDtoRoleEnum.Pm:
            this.role = "PM";
            break;
          case UserProfileResponseDtoRoleEnum.Station:
            this.role = "Station";
            break;
          case UserProfileResponseDtoRoleEnum.Anonymous:
            this.role = "Anonymous";
            break;
        }
      });

      this.projects = await apiClient.getProjects();

      console.log(this.projects);
    } catch (error) {
      console.log(error);
    }

    try {
      const pid = Cookies.get("pid");
      this.pid = pid !== undefined ? parseInt(pid) : 99999;

      for (var i in this.projects) {
        if (this.pid == this.projects[i].id) {
          ProjectResponseDtoStatusEnum;

          switch (this.projects[i].status) {
            case ProjectResponseDtoStatusEnum.Preiop:
              this.status = "Pre-IOP";
              break;
            case ProjectResponseDtoStatusEnum.Iop:
              this.status = "IOP";
              break;
            case ProjectResponseDtoStatusEnum.Postiop:
              this.status = "Post-IOP";
              break;
            case ProjectResponseDtoStatusEnum.Archived:
              this.status = "Archived";
              break;
          }
          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
});
