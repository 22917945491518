
import { defineComponent } from "vue";
import Navbar from "@/components/NavBar.vue";
import Status from "@/components/StatusView.vue";
import { Api, SignInRequestDto } from "@/api/api";

export default defineComponent({
  name: "App",
  components: {
    Navbar,
    Status,
  },
});
