
import Cookies from "js-cookie";
import { defineComponent, ref, computed } from "vue";
import { Api, UploadLoraFileRequestDto } from "@/api/api";
import { apiClient } from "@/scrpts/service";
import moment from "moment";

export default defineComponent({
  data() {
    return {
      pid: 0,
      sid: 0,
      projects: [],
      stations: [],
      auth_token: "",
      text_upload: "",
      inputFile: null,
    } as {
      pid: any;
      sid: any;
      projects: {
        id: number;
        name: string;
      }[];
      stations: {
        id: number;
        name: string;
      }[];
      text_upload: string;
      auth_token: string;
      inputFile: File | null;
    };
  },
  methods: {
    async project_change() {
      const response = await apiClient.api.stationControllerGetStations(
        this.pid
      );
      this.stations = [];
      for (var i in response.data) {
        const item = {
          id: response.data[i].id,
          name: response.data[i].name,
        };
        this.stations.push(item);
      }
      try {
        this.sid = this.stations[0].id;
        Cookies.set("pid", String(this.pid));
        Cookies.set("sid", String(this.stations[0].id));
      } catch {
        alert("No Data!");
      }
    },
    async station_change() {
      Cookies.set("pid", String(this.pid));
      Cookies.set("sid", String(this.sid));
    },
    fileChange(e: any) {
      this.inputFile = e.target.files[0] as File;
      // this.formData.append("file", e.target.files[0]); //放進上傳的檔案
    },
    upload_file() {
      const inputFile = this.inputFile as File;
      const data: UploadLoraFileRequestDto = {
        file: inputFile,
      };

      apiClient.api
        .loraControllerUploadLoraFile(this.pid, this.sid, data)
        .then((response) => {
          alert(this.$t("UploadView.success"));
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          alert(this.$t("UploadView.failed"));
          console.log(error);
        });
    },
  },
  async mounted() {
    this.text_upload = this.$t("AttchUpload.upload");

    const pid = Cookies.get("pid");
    const sid = Cookies.get("sid");

    this.pid = pid !== undefined ? parseInt(pid) : 99999;
    this.sid = sid !== undefined ? parseInt(sid) : 99999;

    this.projects = await apiClient.getProjects();
    this.stations = await apiClient.getStations(this.pid);
  },
});
